import React from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import LookupPageV2 from './layouts/lookupPagev2';
import ScanPage from './layouts/ScanPage';
import CheckinPage from './layouts/ScanPage/Checkin.js'
import Homepage from './layouts/lookupPagev2/pages/Ecommerce/index.jsx'
import { persistor, store } from 'store/store.js';
import { PersistGate } from 'redux-persist/lib/integration/react';
class App extends React.Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <Switch>
              <Route exact path="/scan" component={ScanPage} />
              <Route exact path="/checkIn" component={CheckinPage} />
              <Route exact path="/home" component={Homepage} />
              <Route exact path="/lookup/:id" component={LookupPageV2} />
              <Route exact path="/profile/:id" component={LookupPageV2} />
              <Route exact path="/certificate/:id" component={LookupPageV2} />
              <Route path="/lookupv2/:id" component={LookupPageV2} />
                <Route path='/' component={ScanPage} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    )
  }
}
App.propTypes = {
  store: PropTypes.object.isRequired
}

export default (App)