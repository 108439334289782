import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import strings from 'constants/strings';
import React from 'react';
import Timeline from "../../components/Timeline/TimelineEducation.jsx";




class Journey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tx: ""
    }
    this.data = this.props.data;
  }

  handleClose = () => {
    this.setState({ open: false });
  };

  showBlockhainAlert = (tx) => {
    this.setState({ open: true, tx: tx });
  }

  render() {
    
    // let page = this.data.page;
    // let total = this.data.total;
    // let gln = this.data.gln;

    // if(!total){
    //   total = 1;
    // }
    // const video = this.props.video;
    // let resultList = []
    // for(let num = 0; num < total ; num ++){
    //   let resultdata = [];
    //   let j = 0;
    //   let u = 0;
    //   const tempdata = this.data.diary[num];
    //   if (tempdata) {
    //     for (j = tempdata.length - 1; j >= 0; j--) {
    //       resultdata.push(tempdata[j][0]);
    //       resultdata[u].dateall = [];
    //       u++;
    //     }
    //     var checkout = 0;
    //     for (j = 0; j < tempdata.length; j++) {
    //       checkout = tempdata.length - 1 - j;
    //       for (u = 0; u < tempdata[checkout].length; u++) {
    //         resultdata[j].dateall.push({
    //           date: tempdata[checkout][u].date,
    //           user: tempdata[checkout][u].from_name,
    //           txID: tempdata[checkout][u].txID,
    //           step_attribute: tempdata[checkout][u].step_attribute,
    //           detail: tempdata[checkout][u].detail,
    //           image: tempdata[checkout][u].images
    //         });
    //       }
    //     }
    //   }

    //   resultList [num] = resultdata;
    // }
    
    

    // console.log("result", resultList);

    return (
      <div>
        {/* <Timeline gln={gln} page={page} total={total} stories={resultList} tag={strings.tracerbility_journey} onConfirm={this.showBlockhainAlert} video={video} /> */}
        <Timeline onConfirm={this.showBlockhainAlert}  />
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          style={{ padding: '10px', paddingBottom: '70px', paddingTop: '70px', zIndex: 3000 }}
          fullScreen={true}
        >
          <DialogContent style={{ padding: '0px' }}>
            <iframe title="blockchain" style={{ height: '100vh', width: '100%' }} src={'https://blockchain.agridential.vn/tx/' + this.state.tx} />
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button style={{ color: 'white', background: 'black' }} onClick={this.handleClose} color="primary" autoFocus>
              {strings.close}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Journey;