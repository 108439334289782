import { SAVE_ID, SAVE_ID_SUCCESS, GET_CERTIFICATE_SUCCESS, GET_DIARY, GET_DIARY_FAILURE, GET_DIARY_SUCCESS, GET_DISTRIBUTOR, GET_DISTRIBUTOR_FAILURE, GET_DISTRIBUTOR_SUCCESS, GET_MANUFACTURER_SUCCESS, GET_OVERVIEW, GET_OVERVIEW_FAILURE, GET_OVERVIEW_SUCCESS } from "actions/types.js";
import { Sample_GLN } from 'assets/data.js';
import axios from "axios";
import { LINK_API } from "constants/API.js";
import { Sample, Sample_Alcohol, Sample_Biophap, Sample_Box, Sample_Coffee, Sample_Fruit, Sample_Garden, Sample_Garlic, Sample_Herb, Sample_Honey, Sample_Incense, Sample_Kimchi, Sample_Lakeview, Sample_Longan, Sample_Mango, Sample_Melon, Sample_Mushroom, Sample_Product, Sample_Rice, Sample_Salad, Sample_Spinach, Sample_Tomato, Sample_Tree, Sample_Tungho, Sample_Xetang, Sample_Xetang1 } from "constants/data_product.js";
import cookie from 'react-cookies';
import { call, put, takeLatest } from 'redux-saga/effects';
import strings from '../constants/strings';

//Key google map api
const API_KEY = "AIzaSyCB7o1cgt10O8xP9lLbk7Iikfv5ypvr528";

//function check size of object
Object.size = function (obj) {
    var size = 0, key;
    for (key in obj) {
        if (obj.hasOwnProperty(key)) size++;
    }
    return size;
};

//Save id
function* saveId(id){
    yield put({
        type: SAVE_ID_SUCCESS,
        id: id.id
    })
}
//Get overview
function* getOverview(scanID) {
    try {
        //console.log(scanID)

        //Load ngôn ngữ từ Cookie
        let language = cookie.load("language");
        language = (language) ? language : "vi";
        strings.setLanguage(language)

        //Kiểm tra data mẫu
        var overview = ""; //Biến chứa dữ liệu phần overview
        var introData = ""; //Biến chứa dữ liệu phần giới thiệu sản phẩm
        let id = parseInt(scanID.id);
        if (id >= 21681 && id <= 21685) {
            overview = Sample_GLN["0000000000006"];
            introData = Sample_Tomato;
        }
        if (id >= 42745 && id <= 47840) {
            overview = Sample_GLN["0000000000002"];
            introData = Sample_Garlic;
        }
        if (id >= 20001 && id <= 21120) {
            overview = Sample_GLN["0000000020001"];
            introData = Sample_Alcohol;
        }
        if (id >= 50897 && id <= 53024) {
            overview = Sample_GLN["0000000000014"];
            introData = Sample_Mushroom;
        }

        if (id >= 76796 && id <= 76800) {
            overview = Sample_GLN["0000000000015"];
            introData = Sample_Biophap;
        }
        id = scanID.id;
        // console.log("id", id)
        if (Sample_GLN[id]) {
            let checkNew = false;
            let checkGarden = false;
            let gs1State = "";
           
            const overviews = Sample_GLN[id];
            // console.log("Check function", overviews[0] === "CERTIFICATE")
            if (overviews[0] === "FRUIT") {
                overview = overviews[1][language];
                introData = Sample_Fruit[language]
            }
            if (overviews[0] === "TREE") {
                overview = overviews[1];
                introData = Sample_Tree;
            }
            if (overviews[0] === "COFFEE") {
                overview = overviews[1][language];
                introData = Sample_Coffee[language];
                gs1State = overviews[0];
            }
            if (overviews[0] === "RICE") {
                overview = overviews[1];
                introData = Sample_Rice;
            }
            if (overviews[0] === "GARLIC") {
                overview = overviews[1];
                introData = Sample_Garlic;
            }
            if (overviews[0] === "HONEY") {
                overview = overviews[1];
                introData = Sample_Honey;
            }
            if (overviews[0] === "INCENSE") {
                overview = overviews[1];
                introData = Sample_Incense;
            }
            if (overviews[0] === "BIOPHAP") {
                overview = overviews[1];
                introData = Sample_Biophap;
            }
            if (overviews[0] === "ALCOHOL") {
                overview = overviews[1];
                introData = Sample_Alcohol;
            }
            if (overviews[0] === "TOMATO") {
                overview = overviews[1];
                introData = Sample_Tomato;
            }
            if (overviews[0] === "TUNGHO") {
                overview = overviews[1];
                introData = Sample_Tungho;
            }
            if (overviews[0] === "BOX") {
                overview = overviews[1];
                introData = Sample_Box;
            }
            if (overviews[0] === "SPINACH") {
                // console.log("Hello Spinach")
                overview = overviews[1];
                introData = Sample_Spinach;
                gs1State = overviews[0];
            }
            if (overviews[0] === "HERB") {
                overview = overviews[1];
                introData = Sample_Herb;
                gs1State = overviews[0];
            }
            if (overviews[0] === "KIMCHI") {
                overview = overviews[1];
                introData = Sample_Kimchi;
            }
            if (overviews[0] === "MELON") {
                overview = overviews[1];
                introData = Sample_Melon;
            }
            if (overviews[0] === "MANGO") {
                overview = overviews[1];
                introData = Sample_Mango;
            }
            if (overviews[0] === "SALAD") {
                overview = overviews[1];
                introData = Sample_Salad
            }
            if (overviews[0] === "MUSHROOM") {
                overview = overviews[1];
                introData = Sample_Mushroom;
            }
            if (overviews[0] === "LONGAN") {
                overview = overview[1];
                introData = Sample_Longan;
            }
            if (overviews[0] === "XETANG") {
                overview = overviews[1];
                introData = Sample_Xetang;
            }
            if (overviews[0] === "XETANG1") {
                overview = overviews[1];
                introData = Sample_Xetang1;
            }
            if (overviews[0] === "CITY") {
                overview = overviews[1];
                introData = Sample_Lakeview;
            }
            if (overviews[0] === "CERTIFICATE") {
                // console.log("here",overviews)
                overview = overviews[1];
                // console.log("-----")
                // console.log("overview", overviews[1])
                // console.log("-----")
                introData = Sample_Longan;
            }
            let type = overviews[0];
            // console.log("type", overviews[0])
            let sample_data = ""
            if (type !== "COFFEE" && type !== "FRUIT") {
                sample_data = overviews[1];
            } else {
                sample_data = overviews[1][language];
            }

            // console.log("Sample Data", sample_data)
            let idCode = sample_data.objectInfo.idCode; //Mã id show trên overview


            //Title ID và ngày kích hoạt
            let titleID = "";
            let activation_date = "";
            if (type === "BOX") {
                activation_date = strings.packing_date;
                titleID = strings.ID_code_box;
            } else {
                if (type === "OBJECT") {
                    activation_date = strings.registration_date;
                    titleID = strings.ID_code_object;
                } else {
                    // console.log("check id object")
                    activation_date = strings.activation_date;
                    titleID = strings.ID_code;
                    // titleID = strings.ID_code;
                }
            }

            //Date and logo
            let listLogo = [];
            let date = "";
            // console.log("hello")
            if (type === "OBJECT") {
                const tempImage = sample_data.objectInfo;
                if (tempImage.image) {
                    if (tempImage.image.length !== 0) {
                        if (tempImage.image.length > 1) {
                            for (let x = 0; x < tempImage.image.length; x++) {
                                let itemsTemp = {
                                    original: tempImage.image[x].url,
                                    thumbnail: tempImage.image[x].url,
                                    className: "imageSlider"
                                }
                                listLogo.push(itemsTemp)
                            }
                        } else {
                            let itemsTemp = {
                                original: tempImage.image[0].url,
                                thumbnail: tempImage.image[0].url,
                                className: "imageSlider"
                            }
                            listLogo.push(itemsTemp)
                        }
                    }
                }
                date = sample_data.objectInfo.registeredDate;
            } else {
                // console.log("Intro", introData)
                const listImage = introData.logo;
                if (!Array.isArray(listImage)) {
                    let itemsTemp = {
                        original: introData.logo,
                        thumbnail: introData.logo,
                        className: "imageSlider"
                    }
                    listLogo.push(itemsTemp);
                } else {
                    listLogo = listImage;
                }
                date = introData.dateBegin;
            }
            // console.log(overviews[1][language])
            //Check status
            let Status = ""
            let data_diary = [];
            if (type !== "COFFEE" && type !== "FRUIT") {
                data_diary = overviews[1].diary;
            } else {
                data_diary = overviews[1][language].diary;
            }
            // console.log("Check diary", data_diary)
            if (type !== "BOX") {
                if (type !== "OBJECT") {
                    if (sample_data.productInfo.status === "1") {
                        Status = "Đã bán"
                    } else {
                        Status = "Chưa bán"
                    }
                } else {
                    Status = ""; //Object chưa có trạng thái
                }
            }
            else {
                let tempCheck = sample_data.boxInfo.status;
                if (tempCheck === "0") {
                    Status = "Lưu kho";
                }
                if (tempCheck === "1") {
                    Status = "Đã bán";
                }
                if (tempCheck === "3") {
                    Status = "Đang vận chuyển"
                }
                if (tempCheck === "4") {
                    Status = "Đã đến nơi nhận"
                }
            }

            //detail info
            let infoDetail = "";
            if (checkNew) {
                if (type === "OBJECT") {
                    const detailTemp_object = sample_data.objectInfo;
                    if (detailTemp_object.options && detailTemp_object.options.length !== 0) {
                        infoDetail = detailTemp_object.options;
                    }
                } else {
                    const detailTemp_productInfo = sample_data.productInfo;
                    if (detailTemp_productInfo.options && detailTemp_productInfo.options.length !== 0) {
                        infoDetail = detailTemp_productInfo.options;
                    }
                }
            } else {
                if (type === "BOX") {
                    const detailTemp_object = sample_data.productInfo;
                    if (detailTemp_object.options && detailTemp_object.options.length !== 0) {
                        infoDetail = detailTemp_object.options;
                    } else {
                        infoDetail = introData.overview;
                    }
                } else {
                    infoDetail = introData.overview;
                }
            }
            let checkGroupDetail = 0;
            if (typeof (infoDetail) === "object") {
                var size = Object.size(infoDetail);
                if (size === 0) {
                    checkGroupDetail = 0;
                }
                else {
                    checkGroupDetail = 1;
                }
            } else {
                if (Array.isArray(infoDetail)) {
                    if (infoDetail.length === 0) {
                        checkGroupDetail = 0;
                    } else {
                        checkGroupDetail = 2;
                    }
                }
            }

            // console.log("Size", size)
            // console.log("check logo", listLogo)
            // console.log("Check new", checkNew)
            // console.log("Check garden", checkGarden)
            // console.log("Info detail", infoDetail)
            // console.log("Check group detail", checkGroupDetail)

            let boxInfo = [];
            if (sample_data.boxInfo) {
                let temp_box = sample_data.boxInfo;
                if (temp_box.amount) {
                    boxInfo.push({
                        key: "Số lượng sản phẩm",
                        value: temp_box.amount
                    })
                }
                if (temp_box.start) {
                    boxInfo.push({
                        key: "Tem bắt đầu",
                        value: temp_box.start
                    })
                }
                if (temp_box.end) {
                    boxInfo.push({
                        key: "Tem kết thúc",
                        value: temp_box.end
                    })
                }
            }

            //Check xem ảnh có file ở dạng panorama hay ko
            var checkPano = false;
            if (sample_data.panorama) {
                // console.log("Check panorama",sample_data.panorama )
                checkPano = sample_data.panorama
            }
            // console.log("overview1", overview)
            yield put({
                type: GET_OVERVIEW_SUCCESS,
                data: overview,
                intro: introData,
                sample: true,
                new: checkNew,
                checkGarden: checkGarden,
                id: scanID.id,
                showId: idCode, //Mã show ra trong overview
                gs1State: gs1State, // Kiểm tra trạng thái gs1 để hiển thị tra cứu
                detailInfo: infoDetail, //Thông tin chi tiết
                status: Status, //Trạng thái
                titleId: titleID,
                logo: listLogo,
                date: date,
                activation_date: activation_date,
                checkGroupDetail: checkGroupDetail,
                banner: introData.banner,
                boxInfo: boxInfo,
                panorama: checkPano,
            });

            //process data manufacturer
            // console.log("Check data", sample_data)
            let cooName = sample_data.cooperativeInfo.name;
            let cooDes = sample_data.cooperativeInfo.description ? sample_data.cooperativeInfo.description : "";
            let coo_ava = sample_data.cooperativeInfo.avatar ? (sample_data.cooperativeInfo.avatar.length > 0 ? sample_data.cooperativeInfo.avatar : []) : [];
            let cooTx = sample_data.cooperativeInfo.txId ? sample_data.cooperativeInfo.txId : "";
            // let cooMap = "https://www.google.com/maps/embed/v1/place?key=" + API_KEY + "&q=" + sample_data.cooperativeInfo.address;

            let cooMap = "";

            if (sample_data.setting && sample_data.setting.satelliteImagery) {
                cooMap = "https://www.google.com/maps/embed/v1/place?key=" + API_KEY + "&q=" + sample_data.cooperativeInfo.address + "&maptype=satellite";
            }
            else {
                cooMap = "https://www.google.com/maps/embed/v1/place?key=" + API_KEY + "&q=" + sample_data.cooperativeInfo.address;
            }

            let cooImage = [];
            for (let j = 0; j < coo_ava.length; j++) {
                cooImage.push({
                    src: coo_ava[j].url,
                    thumbnail: coo_ava[j].url,
                    thumbnailHeight: 2,
                    caption: cooName
                })
            }
            let coo_Cert = sample_data.cooperativeInfo.certificates ? (sample_data.cooperativeInfo.certificates.length > 0 ? sample_data.cooperativeInfo.certificates : []) : [];
            let cooCert = [];
            for (let j = 0; j < coo_Cert.length; j++) {
                cooCert.push({
                    src: coo_Cert[j].url,
                    thumbnail: coo_Cert[j].url,
                    thumbnailHeight: 2,
                    caption: cooName + " - certificate"
                })
            }
            let cooInfo = [];
            if (sample_data.cooperativeInfo.gln) {
                cooInfo.push({
                    key: strings.enterprise_code,
                    value: sample_data.cooperativeInfo.gln
                })
            }
            if (sample_data.cooperativeInfo.phone) {
                cooInfo.push({
                    key: strings.phone,
                    value: sample_data.cooperativeInfo.phone,
                })
            }
            if (sample_data.cooperativeInfo.email) {
                cooInfo.push({
                    key: strings.email,
                    value: sample_data.cooperativeInfo.email,
                })
            }
            if (sample_data.cooperativeInfo.website) {
                cooInfo.push({
                    key: strings.website,
                    value: sample_data.cooperativeInfo.website,
                })
            }
            if (sample_data.cooperativeInfo.address) {
                cooInfo.push({
                    key: strings.address,
                    value: sample_data.cooperativeInfo.address,
                })
            }
            if (sample_data.objectInfo.zoneAddress) {
                cooInfo.push({
                    key: strings.address_area,
                    value:  sample_data.objectInfo.zoneAddress.city,
                })
            }
            yield put({
                type: GET_MANUFACTURER_SUCCESS,
                cooName: cooName,
                cooInfo: cooInfo,
                cooDes: cooDes,
                cooImage: cooImage,
                cooCert: cooCert,
                cooMap: cooMap,
                cooTx: cooTx
            })

            //proccess Data diary
            let stories = [];
            let trackingMap = [];
            let video = introData.video ? introData.video : "";
            //console.log("link video", introData)
            const list_stories = data_diary;
            //console.log("list stories",list_stories )
            for (let j = 0; j < list_stories.length; j++) {
                stories[j] = [];
                trackingMap[j] = []
                const list_step = list_stories[j];
                //console.log("list_step", list_step)
                for (let z = 0; z < list_step.length; z++) {
                    stories[j].push({
                        inverted: true,
                        badgeColor: "success",
                        title: list_step[z][0].step,
                        titleColor: "success",
                        name: list_step[z][0].step,
                        description: list_step[z][0].step_desc,
                        badgeIcon: list_step[z][0].step_icon ? list_step[z][0].step_icon : "agri.svg", //icon badge
                        firstElement: {},
                        listElement: []
                    })
                    //console.log(stories)
                    const list_info = list_step[z].reverse();
                    //console.log("list info", list_info)
                    for (let m = 0; m < list_info.length; m++) {
                        let images_temp = [];
                        if (list_info[m].moreInfo) {
                            if(list_info[m].moreInfo.gps){
                                if(list_info[m].moreInfo.gps.coords){
                                    list_info[m].moreInfo.gps.coords.from_name = list_info[m].from_name;
                                    trackingMap[j].push(list_info[m].moreInfo.gps.coords)
                                }
                            }
                        }
                        if (list_info[m].images) {
                            for (let k = 0; k < list_info[m].images.length; k++) {
                                images_temp.push({
                                    src: list_info[m].images[k].url,
                                    thumbnail: list_info[m].images[k].url,
                                    thumbnailHeight: 1,
                                    caption: ''
                                })
                            }
                        }
                        if (m === 0) {
                            stories[j][z].firstElement = {
                                who: list_info[m].from_name,
                                when: parseInt(list_info[m].date),
                                detail: list_info[m].step_attribute,
                                txID: list_info[m].txID,
                                step_attribute: list_info[m].step_attribute,
                                image: images_temp
                            }
                        } else {
                            stories[j][z].listElement.push({
                                who: list_info[m].from_name,
                                when: parseInt(list_info[m].date),
                                detail: list_info[m].step_attribute,
                                txID: list_info[m].txID,
                                step_attribute: list_info[m].step_attribute,
                                image: images_temp
                            })
                        }
                    }
                }

            }
            //console.log("Check function")
            const totalPage = stories.length;
            let pages = [{
                text: "Mùa vụ",
                active: false
            }];
            for (let j = 0; j < totalPage; j++) {
                if (j === 0) {
                    pages.push({
                        text: j + 1,
                        active: true
                    })
                } else {
                    pages.push({
                        text: j + 1,
                        active: false
                    })
                }
            }
            //console.log(pages)
            yield put({
                type: GET_DIARY_SUCCESS,
                stories: stories,
                video: video,
                totalPage: totalPage,
                pages: pages,
                tracking: trackingMap
            })

            let data_distributor = "";
            if (overview.distributor) {
                data_distributor = overview.distributor;
            }
            let distributor_list = []
            for (let j = 0; j < data_distributor.length; j++) {
                //console.log("Check value j: ", j)
                distributor_list.push({
                    "main": [],
                    "name": data_distributor[j].name,
                    "description": data_distributor[j].description,
                    "branchs": data_distributor[j].branchs
                })

                // console.log("Data_distributor", distributor_list[j])
                Object.keys(data_distributor[j]).map((key, index) => {
                    if (key !== "branchs" && key !== "_id" && key !== "uuid" && key !== "name" && key !== "description")
                        if (key === "address") {
                            distributor_list[j].main.push({
                                key: strings.address,
                                value: data_distributor[j][key]
                            })
                        }
                    if (key === "email") {
                        distributor_list[j].main.push({
                            key: strings.email,
                            value: data_distributor[j][key]
                        })
                    }
                    if (key === "phone") {
                        distributor_list[j].main.push({
                            key: strings.phone,
                            value: data_distributor[j][key]
                        })
                    }
                    if (key === "website") {
                        distributor_list[j].main.push({
                            key: strings.website,
                            value: data_distributor[j][key]
                        })
                    }
                    return key;
                })
            }

            if (distributor_list.length > 0) {
                yield put({
                    type: GET_DISTRIBUTOR_SUCCESS,
                    data: distributor_list,
                    check: true
                })
            } else {
                yield put({
                    type: GET_DISTRIBUTOR_SUCCESS,
                    data: distributor_list,
                    check: false
                })
            }

            let certificates = introData.certificate;
            yield put({
                type: GET_CERTIFICATE_SUCCESS,
                data: certificates,
                slider: false
            })
        } else {

            const { response } = yield call(fetchOverview, scanID.id);

            let checkNew = false;
            let checkGarden = false;
            if (response) {
                const type = response.data.data.type;
                overview = response.data.data;
                //Check tem vườn
                if (id === "000000001004700010000" || id === "000000001004700010001" || id === "000000001004700010002" || id === "000000001004700010003"
                    || id === "000000001004700010004" || id === "000000001004700010005" || id === "000000001004700010006" || id === "000000001004700010007" ||
                    id === "000000001004700010008" || id === "000000001004700010009" || id === "000000001004700010010" || id === "000000002003900020000") {
                    const Sample_Tomato_Info = {
                        type: "TOMATO",
                        treeInfo: {
                            idCode: response.data.data.treeInfo.idCode,
                        },
                        farmInfo: response.data.data.farmInfo,
                        cooperativeInfo: response.data.data.cooperativeInfo,
                        productInfo: {
                            name: "Vườn cà chua Cherry",
                            type: "F",
                            description:
                                "Cà chua CHERRY thuộc họ bạch anh, cây thân mềm có thể bò trên mặt đất hoặc leo thường sống ở các vùng có khí hậu mát, là loại thực phẩm thường được sử dụng nhiều trong nấu ăn, chế biến các món trộn hàng ngày",
                            process_code: "T7",
                            create_date: response.data.data.productInfo.create_date,
                            owner_gln: response.data.data.productInfo.owner_gln,
                            trace_cycle: "y",
                            TABLE_NAME: response.data.data.productInfo.TABLE_NAME,
                            sc_address: response.data.data.productInfo.sc_address,
                            status: 0,
                            standard: "FDA",
                            "expiredDate ": "",
                            gtin: response.data.data.productInfo.gtin,
                            blockchain: response.data.data.productInfo.blockchain,
                            index: "1",
                            prefix: "003",
                            __v: 0,
                            id: null,
                            image: ""
                        },
                        diary: [],
                    };
                    overview = Sample_Tomato_Info;
                    introData = Sample_Tomato;
                    checkGarden = true;
                }
                else {
                    if (id === "893852479003000000000" || id === "893852479003000000001") {
                        const Sample_Alcohol_Info = {
                            type: "ALCOHOL",
                            treeInfo: {
                                idCode: response.data.data.treeInfo.idCode,
                            },
                            farmInfo: response.data.data.farmInfo,
                            cooperativeInfo: response.data.data.cooperativeInfo,
                            productInfo: {
                                name: "Vườn Đẳng Sâm",
                                type: "F",
                                description:
                                    "Đảng sâm bắc hay còn gọi là Đẳng sâm là một loài cây sống lâu năm có nguồn gốc ở khu vực đông bắc châu Á và bán đảo Triều Tiên, thông thường được tìm thấy mọc xung quanh các bờ suối hay các cánh rừng thưa dưới bóng các cây to",
                                process_code: "R1",
                                create_date: response.data.data.productInfo.create_date,
                                owner_gln: response.data.data.productInfo.owner_gln,
                                trace_cycle: "y",
                                TABLE_NAME: "8936071440037",
                                sc_address: response.data.data.productInfo.sc_address,
                                status: 0,
                                standard: "FDA",
                                "expiredDate ": "",
                                gtin: response.data.data.productInfo.gtin,
                                blockchain: response.data.data.productInfo.blockchain,
                                index: "1",
                                prefix: "003",
                                __v: 0,
                                id: null,
                                image: ""
                            },
                            diary: [],
                        };
                        overview = Sample_Alcohol_Info;
                        introData = Sample_Garden;
                        checkGarden = true;
                    }
                    else {
                        switch (type) {
                            case "TREE": {
                                introData = Sample_Tree;
                                break;
                            }
                            case "FRUIT": {
                                introData = Sample_Fruit;
                                break;
                            }
                            case "COFFEE": {
                                introData = Sample_Coffee;
                                break;
                            }
                            case "TRIAL": {
                                introData = Sample;
                                break;
                            }
                            case "GARLIC": {
                                introData = Sample_Garlic;
                                break;
                            }
                            case "HONEY": {
                                introData = Sample_Honey;
                                break;
                            }
                            case "INCENSE": {
                                introData = Sample_Incense;
                                break;
                            }
                            case "BIOPHAP": {
                                introData = Sample_Biophap;
                                break;
                            }
                            case "ALCOHOL": {
                                introData = Sample_Alcohol;
                                break;
                            }
                            case "TOMATO": {
                                introData = Sample_Tomato;
                                break;
                            }
                            case "TUNGHO": {
                                introData = Sample_Tungho;
                                break;
                            }
                            case "SPINACH": {
                                introData = Sample_Spinach;
                                break;
                            }
                            case "HERB": {
                                introData = Sample_Herb;
                                break;
                            }
                            case "KIMCHI": {
                                introData = Sample_Kimchi;
                                break;
                            }
                            case "MELON": {
                                introData = Sample_Melon;
                                break;
                            }
                            case "MANGO": {
                                introData = Sample_Mango;
                                break;
                            }
                            case "SALAD": {
                                introData = Sample_Salad;
                                break;
                            }
                            case "MUSHROOM": {
                                introData = Sample_Mushroom;
                                break;
                            }
                            case "LONGAN": {
                                introData = Sample_Longan;
                                break;
                            }
                            case "OBJECT": {
                                introData = Sample_Mushroom;
                                checkNew = true;
                                break;
                            }
                            case "PRODUCT": {
                                introData = Sample_Product;
                                checkNew = true;
                                break;
                            }
                            case "ACOHOL_GARDEN": {
                                introData = Sample_Alcohol;
                                break;
                            }
                            case "BOX": {
                                introData = Sample_Box;
                                break;
                            }
                            default: {
                                introData = null;
                                break;
                            }
                        }

                    }
                }

                //Mã idCode show trên overview
                let idCode = ""; //Mã id show trên overview
                if (type !== 'ALCOHOL') {
                    if (id.length === 13) {
                        idCode = response.data.data.mappingInfo.id
                    } else if (type === "OBJECT") {
                        idCode = id
                    }
                    else if (type === "BOX") {
                        idCode = response.data.data.mappingInfo.id
                    }
                    else {
                        idCode = response.data.data.productInfo.gtin
                    }
                } else {
                    idCode = response.data.data.objectInfo.idCode
                }
                //console.log("idCode", idCode)
                //Title ID và ngày kích hoạt
                let titleID = "";
                let activation_date = "";
                if (type === "BOX") {
                    activation_date = strings.packing_date;
                    titleID = strings.ID_code_box;
                } else {
                    if (type === "OBJECT") {
                        activation_date = strings.registration_date;
                        titleID = strings.ID_code_object;
                    } else {
                        activation_date = strings.activation_date;
                        titleID = strings.ID_code;
                    }
                }
                // console.log("Check date", activation_date)

                //Date and logo
                let listLogo = [];
                let date = "";
                if (type === "OBJECT") {
                    const tempImage = response.data.data.objectInfo;
                    if (tempImage.image) {
                        if (tempImage.image.length !== 0) {
                            if (tempImage.image.length > 1) {
                                for (let x = 0; x < tempImage.image.length; x++) {
                                    let itemsTemp = {
                                        original: tempImage.image[x].url,
                                        thumbnail: tempImage.image[x].url,
                                        className: "imageSlider"
                                    }
                                    listLogo.push(itemsTemp)
                                }
                            } else {
                                let itemsTemp = {
                                    original: tempImage.image[0].url,
                                    thumbnail: tempImage.image[0].url,
                                    className: "imageSlider"
                                }
                                listLogo.push(itemsTemp)
                            }
                        }
                    }
                    date = response.data.data.objectInfo.registeredDate;
                } else {
                    if (type !== "ALCOHOL") {
                        const tempImage = response.data.data.productInfo;
                        if (tempImage.image) {
                            if (tempImage.image.length !== 0) {
                                if (tempImage.image.length > 1) {
                                    for (let x = 0; x < tempImage.image.length; x++) {
                                        let itemsTemp = {
                                            original: tempImage.image[x].url,
                                            thumbnail: tempImage.image[x].url,
                                            className: "imageSlider"
                                        }
                                        listLogo.push(itemsTemp)
                                    }
                                } else {
                                    let itemsTemp = {
                                        original: tempImage.image[0].url,
                                        thumbnail: tempImage.image[0].url,
                                        className: "imageSlider"
                                    }
                                    listLogo.push(itemsTemp)
                                }
                            }
                        }
                        date = response.data.data.productInfo.registeredDate ? parseInt(response.data.data.productInfo.registeredDate) : response.data.data.objectInfo.registeredDate;
                    } else {
                        listLogo = introData.logo;
                        date = introData.dateBegin;
                    }
                }
                //Get diary
                const diary = yield call(fetchDiary, scanID.id);
                const data_diary = diary.data;
                //yield put({ type: GET_DIARY_SUCCESS, data: data_diary.data, errorCode: data_diary.errorCode });
                //Check status
                let Status = ""
                if (type !== "BOX") {
                    if (type !== "OBJECT") {
                        if (response.data.data.productInfo.status === "1") {
                            Status = strings.sold
                        } else {
                            Status = ""
                        }
                    } else {
                        Status = ""; //Object chưa có trạng thái
                    }
                }
                else {
                    let tempCheck = response.data.data.boxInfo.status;
                    if (tempCheck === "0") {
                        Status = "Lưu kho";
                    }
                    if (tempCheck === "1") {
                        Status = strings.sold;
                    }
                    if (tempCheck === "3") {
                        Status = "Đang vận chuyển"
                    }
                    if (tempCheck === "4") {
                        Status = "Đã đến nơi nhận"
                    }
                }
                //console.log("Check infodetail")
                //detail info
                let infoDetail = "";
                if (checkNew) {
                    if (type === "OBJECT") {
                        const detailTemp_object = response.data.data.objectInfo;
                        if (detailTemp_object.options && detailTemp_object.options.length !== 0) {
                            infoDetail = detailTemp_object.options;
                        }
                    } else {
                        const detailTemp_productInfo = response.data.data.productInfo;
                        if (detailTemp_productInfo.options && detailTemp_productInfo.options.length !== 0) {
                            infoDetail = detailTemp_productInfo.options;
                        }
                    }
                } else {
                    if (type === "BOX") {
                        const detailTemp_object = response.data.data.productInfo;
                        if (detailTemp_object.options && detailTemp_object.options.length !== 0) {
                            infoDetail = detailTemp_object.options;
                        } else {
                            infoDetail = introData.overview;
                        }
                    } else {
                        infoDetail = introData.overview;
                    }
                }
                let checkGroupDetail = 0;
                if (typeof (infoDetail) === "object" && !Array.isArray(infoDetail)) {
                    var size_sub = Object.size(infoDetail);
                    if (size_sub === 0) {
                        checkGroupDetail = 0;
                    }
                    else {
                        checkGroupDetail = 1;
                    }
                } else {
                    if (Array.isArray(infoDetail)) {
                        if (infoDetail.length === 0) {
                            checkGroupDetail = 0;
                        } else {
                            if (Array.isArray(infoDetail[0].value)) {
                                checkGroupDetail = 2;
                            }
                            else {
                                checkGroupDetail = 3
                            }
                        }
                    }
                }
                let banner = "";
                if (response.data.data.cooperativeInfo.banner && response.data.data.cooperativeInfo.banner.secure_url) {
                    banner = response.data.data.cooperativeInfo.banner.secure_url
                }
                else {
                    banner = null;
                }

                let boxInfo = [];
                if (response.data.data.boxInfo) {
                    let temp_box = response.data.data.boxInfo;
                    if (temp_box.amount) {
                        boxInfo.push({
                            key: "Số lượng sản phẩm",
                            value: temp_box.amount
                        })
                    }
                    if (temp_box.start) {
                        boxInfo.push({
                            key: "Tem bắt đầu",
                            value: temp_box.start
                        })
                    }
                    if (temp_box.end) {
                        boxInfo.push({
                            key: "Tem kết thúc",
                            value: temp_box.end
                        })
                    }
                }
                // console.log("Check group detail", checkGroupDetail)
                yield put({
                    type: GET_OVERVIEW_SUCCESS,
                    data: overview, intro: introData,
                    sample: false,
                    new: checkNew,
                    checkGarden: checkGarden,
                    id: scanID.id,
                    showId: idCode, //Mã show ra trong overview
                    //gs1State: action.gs1State, // Kiểm tra trạng thái gs1 để hiển thị tra cứu
                    detailInfo: infoDetail, //Thông tin chi tiết
                    status: Status, //Trạng thái
                    titleId: titleID,
                    logo: listLogo,
                    date: date,
                    activation_date: activation_date,
                    checkGroupDetail: checkGroupDetail,
                    banner: banner,
                    boxInfo: boxInfo
                });
                //process data manufacturer
                const sample_data = response.data.data;
                let cooName = sample_data.cooperativeInfo.name;
                let cooDes = sample_data.cooperativeInfo.description ? sample_data.cooperativeInfo.description : "";
                let coo_ava = sample_data.cooperativeInfo.avatar ? (sample_data.cooperativeInfo.avatar.length > 0 ? sample_data.cooperativeInfo.avatar : []) : [];
                let cooTx = sample_data.cooperativeInfo.txId ? sample_data.cooperativeInfo.txId : "";
                let cooMap = "";

                if (sample_data.setting && sample_data.setting.satelliteImagery) {
                    cooMap = "https://www.google.com/maps/embed/v1/place?key=" + API_KEY + "&q=" + sample_data.cooperativeInfo.address + "&maptype=satellite";
                }
                else {
                    cooMap = "https://www.google.com/maps/embed/v1/place?key=" + API_KEY + "&q=" + sample_data.cooperativeInfo.address;
                }

                let cooImage = [];
                for (let j = 0; j < coo_ava.length; j++) {
                    cooImage.push({
                        src: coo_ava[j].url,
                        thumbnail: coo_ava[j].url,
                        thumbnailHeight: 2,
                        caption: cooName
                    })
                }
                let coo_Cert = sample_data.cooperativeInfo.certificates ? (sample_data.cooperativeInfo.certificates.length > 0 ? sample_data.cooperativeInfo.certificates : []) : [];
                let cooCert = [];
                for (let j = 0; j < coo_Cert.length; j++) {
                    cooCert.push({
                        src: coo_Cert[j].url,
                        thumbnail: coo_Cert[j].url,
                        thumbnailHeight: 2,
                        caption: cooName + " - certificate"
                    })
                }
                let cooInfo = [];
                if (sample_data.cooperativeInfo.gln) {
                    cooInfo.push({
                        key: strings.enterprise_code,
                        value: sample_data.cooperativeInfo.gln
                    })
                }
                if (sample_data.cooperativeInfo.phone) {
                    cooInfo.push({
                        key: strings.phone,
                        value: sample_data.cooperativeInfo.phone,
                    })
                }
                if (sample_data.cooperativeInfo.email) {
                    cooInfo.push({
                        key: strings.email,
                        value: sample_data.cooperativeInfo.email,
                    })
                }
                if (sample_data.cooperativeInfo.website) {
                    cooInfo.push({
                        key: strings.website,
                       value: sample_data.cooperativeInfo.website,
                    })
                }
                if (sample_data.cooperativeInfo.address) {
                    cooInfo.push({
                        key: strings.address,
                        value: sample_data.cooperativeInfo.address,
                    })
                }
                if (sample_data.objectInfo.zoneAddress) {
                    cooInfo.push({
                        key: strings.address_area,
                        value: sample_data.objectInfo.zoneAddress.city,
                    })
                }
                yield put({
                    type: GET_MANUFACTURER_SUCCESS,
                    cooName: cooName,
                    cooInfo: cooInfo,
                    cooDes: cooDes,
                    cooImage: cooImage,
                    cooCert: cooCert,
                    cooMap: cooMap,
                    cooTx: cooTx
                })

                //proccess Data diary
                let stories = [];
                let trackingMap = [];
                let video = introData.video ? introData.video : "";
                const list_stories = data_diary.data.diary;
                //console.log("Check stories new",list_stories )
                // console.log(list_stories[0][0][0].step)
                for (let j = 0; j < list_stories.length; j++) {
                    stories[j] = [];
                    trackingMap[j] = [];
                    const list_step = list_stories[j];
                    for (let z = 0; z < list_step.length; z++) {
                        let defaultImagesStep = [];
                        if (list_step[z][0].action_image) {
                            let tempDefaultImage = list_step[z][0].action_image;
                            for (let b = 0; b < list_step[z][0].action_image.length; b++) {
                                defaultImagesStep.push({
                                    src: tempDefaultImage[b].url,
                                    thumbnail: tempDefaultImage[b].url,
                                    thumbnailHeight: 1,
                                    caption: ''
                                })

                            }
                        }
                        stories[j].push({
                            inverted: true,
                            badgeColor: "success",
                            title: list_step[z][0].step,
                            titleColor: "success",
                            name: list_step[z][0].step,
                            description: list_step[z][0].step_desc,
                            badgeIcon: list_step[z][0].step_icon ? list_step[z][0].step_icon : "agri.svg", //icon badge
                            firstElement: {},
                            listElement: [],
                            defaultImage: defaultImagesStep
                        })
                        const list_info = list_step[z].reverse();
                        for (let m = 0; m < list_info.length; m++) {
                            let images_temp = [];
                            let default_images = []
                            let certificateDetail = []
                            if (list_info[m].moreInfo) { 
                                if (list_info[m].moreInfo.gps) {
                                    if(list_info[m].moreInfo.gps.coords){
                                        list_info[m].moreInfo.gps.coords.from_name = list_info[m].from_name;
                                        trackingMap[j].push(list_info[m].moreInfo.gps.coords)
                                    }
                                }
                                if (list_info[m].moreInfo.certificates) {
                                    certificateDetail = list_info[m].moreInfo.certificates;
                                }

                            }
                            if (list_info[m].images) {
                                for (let k = 0; k < list_info[m].images.length; k++) {
                                    images_temp.push({
                                        src: list_info[m].images[k].url,
                                        thumbnail: list_info[m].images[k].url,
                                        thumbnailHeight: 1,
                                        caption: ''
                                    })
                                }
                            }
                            if (list_info[m].action_image) {
                                for (let k = 0; k < list_info[m].action_image.length; k++) {
                                    default_images.push({
                                        src: list_info[m].action_image[k].url,
                                        thumbnail: list_info[m].action_image[k].url,
                                        thumbnailHeight: 1,
                                        caption: ''
                                    })
                                }
                            }
                            if (m === 0) {
                                stories[j][z].firstElement = {
                                    who: list_info[m].from_name,
                                    when: parseInt(list_info[m].date),
                                    detail: list_info[m].step_attribute,
                                    txID: list_info[m].txID,
                                    step_attribute: list_info[m].step_attribute,
                                    defaultImage: default_images, //ảnh mặc định
                                    image: images_temp, // ảnh người dùng thêm,
                                    certificates: certificateDetail //link file chứng chỉ
                                }
                            } else {
                                stories[j][z].listElement.push({
                                    who: list_info[m].from_name,
                                    when: parseInt(list_info[m].date),
                                    detail: list_info[m].step_attribute,
                                    txID: list_info[m].txID,
                                    step_attribute: list_info[m].step_attribute,
                                    defaultImage: default_images, //ảnh mặc định
                                    image: images_temp, //ảnh người dùng thêm
                                    certificates: certificateDetail //link file chứng chỉ
                                })
                            }
                        }
                    }

                }
                //console.log("Check stories 2", stories)
                const totalPage = stories.length;
                let pages = [{
                    text: "Mùa vụ",
                    active: false
                }];
                // console.log("Pages", pages)
                for (let j = 0; j < totalPage; j++) {
                    if (j === 0) {
                        pages.push({
                            text: j + 1,
                            active: true
                        })
                    } else {
                        pages.push({
                            text: j + 1,
                            active: false
                        })
                    }
                }
                yield put({
                    type: GET_DIARY_SUCCESS,
                    stories: stories,
                    video: video,
                    totalPage: totalPage,
                    pages: pages, 
                    tracking: trackingMap
                })

                const fetchDataDistributor = yield call(fetchDistributor, scanID.id);
                console.log("fetchDataDistributor", fetchDataDistributor)
                const data_distributor = fetchDataDistributor.data.data.distributors.length > 0 ? fetchDataDistributor.data.data.distributors[0].distributor : [];
                console.log("fetchDataDistributor", data_distributor)
                let distributor_list = []
                if(data_distributor.length > 0){
                    distributor_list.push({
                        "main": [],
                        "name": data_distributor[0].name,
                        "description": data_distributor[0].description,
                        "branchs": data_distributor[0].branchs
                    })
                    Object.keys(data_distributor[0]).map((key, index) => {
                        if (key !== "branchs" && key !== "_id" && key !== "uuid" && key !== "name" && key !== "description")
                            if (key === "address") {
                                distributor_list[0].main.push({
                                    key: strings.address,
                                    value: data_distributor[0][key]
                                })
                            }
                        if (key === "email") {
                            distributor_list[0].main.push({
                                key: strings.email,
                                value: data_distributor[0][key]
                            })
                        }
                        if (key === "phone") {
                            distributor_list[0].main.push({
                                key: strings.phone,
                                value: data_distributor[0][key]
                            })
                        }
                        if (key === "website") {
                            distributor_list[0].main.push({
                                key: strings.website,
                                value: data_distributor[0][key]
                            })
                        }
                        return key;
                    })
                }
                if (distributor_list.length > 0) {
                    yield put({
                        type: GET_DISTRIBUTOR_SUCCESS,
                        data: distributor_list,
                        check: true
                    })
                } else {
                    yield put({
                        type: GET_DISTRIBUTOR_SUCCESS,
                        data: distributor_list,
                        check: false
                    })
                }

                let certificates = [];
                console.log("Check certificate")
                if (type === "ALCOHOL") {
                    certificates = introData.certificate;
                    yield put({
                        type: GET_CERTIFICATE_SUCCESS,
                        data: certificates,
                        slider: false
                    })
                } else {
                    if (type !== "OBJECT") {
                        console.log("Check certificate")
                        if (overview.productInfo.certificates) {
                            let tempCertificates = overview.productInfo.certificates;
                            console.log("Check",tempCertificates )
                            for (let z = 0; z < tempCertificates.length; z++) {
                                certificates.push({
                                    original: tempCertificates[z].url, thumbnail: tempCertificates[z].url, className: "imageSlider"
                                })
                            }
                            yield put({
                                type: GET_CERTIFICATE_SUCCESS,
                                data: certificates,
                                slider: true
                            })
                        } else {
                            yield put({
                                type: GET_CERTIFICATE_SUCCESS,
                                data: certificates,
                                slider: false
                            })
                        }
                    }
                }
            } else {
                yield put({
                    type: GET_OVERVIEW_SUCCESS,
                    data: "", intro: null,
                    sample: false,
                    new: false,
                    checkGarden: false,
                    id: scanID.id
                });
            }
        }
    } catch (error) {
        yield put({ type: GET_OVERVIEW_FAILURE, payload: "" });
    }
}
function fetchOverview(id) {
    return axios({
        method: "GET",
        url: LINK_API.GET_TREE_NEW + "/baseinfo?gln=" + id + "&lg=vi&nestedgroup=true"
    })
        .then(response => ({ response }))
        .catch(error => ({ error }))
}

//get diary
function* getDiary(scanID) {
    try {
        const response = yield call(fetchDiary, scanID.id);
        const data = response.data;
        yield put({ type: GET_DIARY_SUCCESS, data: data.data, errorCode: data.errorCode });
    } catch (error) {
        yield put({ type: GET_DIARY_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchDiary(id) {
    return axios({
        method: "GET",
        url: LINK_API.GET_TREE_NEW + "?gln=" + id + "&lg=vi&nestedgroup=true"
    })
}

//get Distributor
function* getDistributor(data) {
    try {
        const response = yield call(fetchDistributor, data.id);
        const data_fetch = response.data;
        yield put({ type: GET_DISTRIBUTOR_SUCCESS, data: data_fetch.data, errorCode: data_fetch.errorCode });
    } catch (error) {
        yield put({ type: GET_DISTRIBUTOR_FAILURE, payload: "", errorCode: 0 });
    }
}
function fetchDistributor(id) {
    return axios({
        method: "GET",
        url: LINK_API.GET_DISTRIBUTOR + '?gln=' + id + '&lg=vi'
    })
}


//root saga
export default function* rootSaga() {
    yield takeLatest(GET_OVERVIEW, getOverview)
    yield takeLatest(GET_DIARY, getDiary)
    yield takeLatest(GET_DISTRIBUTOR, getDistributor)
    yield takeLatest(SAVE_ID, saveId)
}