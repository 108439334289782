// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import sortdown from 'assets/icons/sort-down.png';
import sortup from 'assets/icons/sort-up.png';
import cx from "classnames";
import Accordion from "components/Accordion/Accordion.jsx";
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Pagination from "components/Pagination/Pagination.jsx";
import strings from 'constants/strings';
import moment from "moment";
import React from "react";
import Gallery from 'react-grid-gallery';
import { connect } from 'react-redux';
import timelineStyle from "./TimelineEduStyle";
import Card from 'components/Card/Card.jsx';
import SettingIcon from "mdi-react/ClockIcon"
import ProfileIcon from "mdi-react/FaceProfileIcon"
import InformationIcon from "mdi-react/InformationIcon"
import ImageIcon from "mdi-react/ImageIcon"
import LinkIcon from "mdi-react/ShieldLinkVariantIcon"
class Timeline extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      stories: [],
      pages: [],
      fullStories: [],
      totalPages: 10,
      pageActive: 1,
      video: "",
      color: "info",
      videoStatus: false,
      dataTracking: [],
      trackingState: [],
      active: []
    }
    this.onClickTx = this.onClickTx.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleVideo = this.handleVideo.bind(this);
    this.collap = this.collap.bind(this);
  }
  async  handleChange(page) {
    if (page !== "Mùa vụ") {
      const tempPages = this.state.pages;
      tempPages[page].active = true;
      tempPages[this.state.pageActive].active = false;
      await this.setState({
        pages: tempPages,
        pageActive: page,
        stories: this.state.fullStories[page - 1].reverse()
      })
    }
  }
  handleChangeNext() {

  }
  handleChangePrev() {

  }
  componentDidMount() {
    if (this.props.diary) {
      if (this.props.diary.stories.length > 0) {
        let stateCollap = this.props.diary.stories[0].map(()=>{
          return 1
        })
    
        
        this.setState({
          active: stateCollap,
          stories: this.props.diary.stories[0].reverse(),
          fullStories: this.props.diary.stories,
          pages: this.props.diary.pages,
          video: this.props.diary.video,
          dataTracking: this.props.diary.tracking ? this.props.diary.tracking.length > 0 ? this.props.diary.tracking : [] : [],
          trackingState: this.props.diary.tracking ? this.props.diary.tracking.length > 0 ? this.props.diary.tracking[0] : [] : [],
        })
      }
    }
  }
  componentWillReceiveProps(preProps) {
    if (preProps.diary !== this.props.diary) {
      if (preProps.diary.stories.length > 0) {
        let stateCollap = preProps.diary.stories[0].map(()=>{
          return 1
        })
        this.setState({
          active: stateCollap,
          stories: preProps.diary.stories[0].reverse(),
          fullStories: preProps.diary.stories,
          pages: preProps.diary.pages,
          video: preProps.diary.video,
          dataTracking: preProps.diary.tracking.length > 0 ? preProps.diary.tracking : [],
          trackingState: preProps.diary.tracking.length > 0 ? preProps.diary.tracking[0] : [],
        })
      }
    }
  }

  onClickTx = (tx) => {
    this.props.onConfirm(tx);
  };
  handleVideo = () => {
    if (this.state.videoStatus === false) {
      document.getElementById('videocontent').style = 'display: none';
      document.getElementById('imagevideo').src = sortdown;
      this.setState({
        videoStatus: true
      })
    } else {
      document.getElementById('videocontent').style = 'display: flex; justify-content: center';
      document.getElementById('imagevideo').src = sortup;
      this.setState({
        videoStatus: false
      })
    }
  };

  collap(key){
    let newActive = this.state.active;
    newActive[key] = 1;
    this.setState({
      active : newActive
    })
  }
  render() {
    const { classes, simple } = this.props;
    return (
      <div className="flexvideo">
        <div className={classes.panel}></div>
        <ul>
          {this.state.stories.map((prop, key) => {
            const timelineBadgeClasses =
              classes.timelineBadge +
              " " +
              classes[prop.badgeColor] +
              " " +
              cx({
                [classes.timelineSimpleBadge]: simple
              });
            return (
              <Card style={key !== 0 ? {marginTop: "50px"}: null}>
              <li className={classes.item} key={key}>
                {prop.badgeIcon ? (
                  <div className={timelineBadgeClasses}>
                    <img alt="badgeIcon" className={classes.badgeIcon} src={require("../../../../assets/icons/" + prop.badgeIcon)} />
                  </div>
                ) : null}
                <GridItem xs={12} sm={12} md={10} className={classes.noPadding}>
                  <GridItem xs={12} sm={12} md={12} lg={12} className={classes.noPadding}>
                    {prop.title ? (
                      <div className={classes.timelineHeading}>
                        <p className={classes.textAction}>{prop.title}</p>
                      </div>
                    ) : null}
                    {prop.description ? <div className={classes.timelineBody}>{prop.description}</div> : null}
                    {prop.defaultImage ? prop.defaultImage.length > 0 ?
                      <GridContainer className="diarycontentdetail">
                      <p className="tagmanufact">{strings.images}: </p>
                      <div style={{ color: 'red' }}>
                        <Gallery style={{ display: 'flex' }} images={prop.defaultImage} rowHeight={70} margin={3} />
                      </div>
                    </GridContainer> :null
                    :null}
                    <hr className="LineBreakDes" style={{ display: 'flex' }}></hr>
                    {prop.firstElement.when ?
                    <GridContainer>
                      <div className={classes.iconSetting}>
                        <SettingIcon size={24} style={{fill: "#868e96"}}/>
                      </div>
                      <div className="diarycontent">
                        <p className="tagmanufact">{strings.when}: </p>
                        <p className="tagmanufactdes">{moment(prop.firstElement.when).format("DD/MM/YYYY HH:mm:ss")}</p>
                      </div>
                    </GridContainer>
                      
                      : null}
                     <GridContainer className={classes.flexColumn}>
                      <div className={classes.iconSetting}>
                        <ProfileIcon size={24} style={{fill: "#868e96"}}/>
                      </div>
                    <div className="diarycontent">
                      <p className="tagmanufact">{strings.who}: </p>
                      <p className="tagmanufactdes">{prop.firstElement.who}</p>
                    </div>
                    </GridContainer>
                    {prop.firstElement.step_attribute ? (
                       <GridContainer className={classes.flexColumn}>
                       <div className={classes.iconSetting}>
                         <InformationIcon size={24} style={{fill: "#868e96"}}/>
                       </div>
                      <div className="diarycontentdetail">
                        <p className="tagmanufact">{strings.details}: </p>
                        {prop.firstElement.step_attribute.split('\n').map((item, i) => {
                          return <p className="tagmanufactdesdetail">{item}</p>;
                        })}
                      </div>
                      </GridContainer>
                    ) : (
                        null
                      )}
                      
                    {(prop.firstElement.image && prop.firstElement.image.length !== 0) ? (
                       <GridContainer className={classes.flexColumn}>
                       <div className={classes.iconSetting}>
                         <ImageIcon size={24} style={{fill: "#868e96"}}/>
                       </div>
                      <div className="diarycontentdetail">
                        <p className="tagmanufact">{strings.images}: </p>
                        <div style={{ color: 'red' }}>
                          <Gallery style={{ display: 'flex' }} images={prop.firstElement.image} rowHeight={70} margin={3} />
                        </div>
                      </div>
                      </GridContainer>
                    ) : (
                        null
                      )}
                    <GridContainer className={classes.flexColumn} style={{marginTop: "22px"}}>
                       <div className={classes.iconSetting}>
                         <LinkIcon size={24} style={{fill: "#868e96"}}/>
                       </div>
                      <div className="diarycontentdetail">
                      <p className="tagmanufact">{strings.proof}:</p>
                      <p className="tagmanufactdestimeline">
                        <a onClick={() => this.onClickTx(prop.firstElement.txID)} href="# ">
                          {prop.firstElement.txID}
                        </a>
                      </p>
                      </div>
                    </GridContainer>
                    {prop.listElement.length > 0 ? (
                      <Accordion
                        active={this.state.active[key]}
                        collapses={[
                          {
                            title: "Xem chi tiết",
                            content: (
                              <div style={{ width: "100%", marginLeft: "-10px" }}>
                                {prop.listElement.map((element, index) => (
                                  <div>
                                    {element.when ? 
                                     <GridContainer className="diarycontent">
                                     <p className="tagmanufact">{strings.when}: </p>
                                     <p className="tagmanufactdes">{moment(element.when).format("DD/MM/YYYY HH:mm:ss")}</p>
                                   </GridContainer> : null}
                                   
                                    <GridContainer className="diarycontent">
                                      <p className="tagmanufact">{strings.who}: </p>
                                      <p className="tagmanufactdes">{element.who}</p>
                                    </GridContainer>
                                    {element.step_attribute ? (
                                      <GridContainer className="diarycontentdetail">
                                        <p className="tagmanufact">{strings.details}: </p>
                                        {element.step_attribute.split('\n').map((itemx, i) => {
                                          return <p className="tagmanufactdesdetail">{itemx}</p>;
                                        })}
                                      </GridContainer>
                                    ) : (
                                        <div></div>
                                      )}
                                    {(element.image && element.image.length !== 0) ? (
                                      <GridContainer className="diarycontentdetail">
                                        <p className="tagmanufact">{strings.images}: </p>
                                        <div style={{ color: 'red' }}>
                                          <Gallery style={{ display: 'flex' }} images={element.image} rowHeight={70} margin={3} />
                                        </div>
                                      </GridContainer>
                                    ) : (
                                        <div></div>
                                      )}
                                    <div className="diarycontentdetail">
                                      <p className="tagmanufact">{strings.proof}:</p>
                                      <p className="tagmanufactdestimeline">
                                        <a onClick={() => this.onClickTx(element.txID)} href="# ">
                                          {element.txID}
                                        </a>
                                      </p>
                                    </div>
                                    <hr className={classes.linebreak} />
                                  </div>

                                ))}
                                
                                {/* <Button size="sm" color="success" onClick={()=>this.collap(key)}> <Up />Thu gọn</Button> */}
                              </div>
                            )
                          }
                        ]} />
                    ) : null}
                  </GridItem>
                </GridItem>
              </li>
              </Card>
            );
          })}
        </ul>
        {this.state.pages.length > 2 ? <div className="flexcenter">
          <Card>
          <GridItem xs={12} sm={12} md={10}>
            <Pagination pages={this.state.pages} color={this.state.color} onClick={this.handleChange} />
          </GridItem>
          </Card>
        </div> : null}
        {/* <div className="styleNoteUser"><i>{strings.noteUser}</i></div> */}
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    diary: state.diary
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
    getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
  }
}
export default withStyles(timelineStyle)(connect(mapStateToProps, mapDispatchToProps)(Timeline));
