export const GET_OVERVIEW = "GET_OVERVIEW"
export const GET_OVERVIEW_SUCCESS = "GET_OVERVIEW_SUCCESS"
export const GET_OVERVIEW_FAILURE = "GET_OVERVIEW_FAILURE"

export const GET_DIARY = "GET_DIARY"
export const GET_DIARY_SUCCESS = "GET_DIARY_SUCCESS"
export const GET_DIARY_FAILURE = "GET_DIARY_FAILURE"

export const GET_DISTRIBUTOR = "GET_DISTRIBUTOR"
export const GET_DISTRIBUTOR_SUCCESS = "GET_DISTRIBUTOR_SUCCESS"
export const GET_DISTRIBUTOR_FAILURE = "GET_DISTRIBUTOR_FAILURE"

export const GET_MANUFACTURER_SUCCESS = "GET_MANUFACTURER_SUCCESS"

export const GET_CERTIFICATE_SUCCESS = "GET_CERTIFICATE_SUCCESS"

export const CHECK_IN = "CHECK_IN"
export const CHECK_IN_SUCCESS = "CHECK_IN_SUCCESS"
export const CHECK_IN_FAILURE = "CHECK_IN_FAILURE"

export const SAVE_ID = "SAVE_ID"
export const SAVE_ID_SUCCESS = "SAVE_ID_SUCCESS"
export const SAVE_ID_FAILURE = "SAVE_ID_FAILURE"