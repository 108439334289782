import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "./scan.css";
import styles from "./styles.js";
import Webcam from "react-webcam";
import Button from 'components/CustomButtons/Button.jsx';
import { connect } from 'react-redux';
import { IMG_LOAD } from 'constants/index.js';
import SweetAlert from "react-bootstrap-sweetalert";
import { LINK_API } from "constants/API.js"
import axios from "axios"
const EthereumTx = require('ethereumjs-tx')

class Scan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delay: 500,
      result: 'Đưa camera hướng về phía mã QR của sản phẩm',
      videoConstraints: {
        width: 400,
        height: 400,
        facingMode: "user",
        alert: null,
        audio: false,
        id: "",
        name: ""
      }
    }
    this.webcamRef = React.createRef(null);
    this.handleCapture = this.handleCapture.bind(this);
    this.handleCheckin = this.handleCheckin.bind(this);
    this.hideAlert = this.hideAlert.bind(this);
    this.handleDirect = this.handleDirect.bind(this);
    this.event = this.event.bind(this)
  }

  async componentDidMount() {
    if (this.props.id) {
      this.setState({
        id: this.props.id
      })
      this.props.getOverview(this.props.id)
    } else {
      this.props.history.push("/scan")
    }
    if (this.props.overview.data) {
      if (this.props.overview.data.objectInfo) {
        this.setState({
          name: this.props.overview.data.objectInfo.name
        })
      }
    }
   
  }
  event = async () => {
    try {
      console.log('start ')
      const res1 = await axios.post('https://staging.agridential.vn/api/v1/events/checkin', {
        "toId": "M0004130001",
        "moreInfo": {},
        "detail": "100kg",
        "images": []
      })
      const data = res1.data.data
      console.log(data)
      const tx = await new EthereumTx(data.raw, { chain: 4 })
      // const tx = {};
      await tx.sign(Buffer.from('0xbd12067e27fa3bd931106902c9830b3000a7356ff16a52d146dd205f71ada4c9'.toString().slice(2), 'hex'))
      data.raw = await `0x${tx.serialize().toString('hex')}`
      data.images = []
      const res2 = await axios.post('https://staging.agridential.vn/api/v1/events/checkin/sendtx', data)
  
      console.log(res2.data)
    } catch (err) {
      console.log('err', err)
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      this.setState({
        id: nextProps.id
      })
    }
    if (nextProps.overview !== this.props.overview) {
      if (nextProps.overview.data) {
        if (nextProps.overview.data.objectInfo) {
          this.setState({
            name: nextProps.overview.data.objectInfo.name
          })
        }
      }
    }
  }
 
  hideAlert() {
    this.setState({
      alert: null
    })
  }
  async handleDirect() {
    this.props.cleanStore();
    this.props.history.push("/profile/" + this.state.id)
  }
  handleCapture() {
    const imageSrc = this.webcamRef.current.getScreenshot();
    this.handleCheckin(imageSrc)
  }
  handleCheckin(image) {
    this.setState({
      alert: (
        <SweetAlert
          warning
          style={{ display: "block" }}
          title="Đang xử lý!"
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          showConfirm={false}
          closeOnClickOutside={false}
        >
          <div style={{ textAlign: "center" }}>
            <img alt="{name}" src={IMG_LOAD} style={{ height: "100px" }} />
          </div>
        </SweetAlert>
      )
    });
    const data = {
      "toId": this.state.id,
      "moreInfo": {},
      "detail": "Checkin event",
      "images": []
    }
    console.log(LINK_API.CHECKIN_INFO)
    axios.post(LINK_API.CHECKIN_INFO, data)
      .then(async response => {
        if (response.data.errorCode === 1) {
          var imageList = [];
          imageList.push(image)
         // const privateKey = "0xbd12067e27fa3bd931106902c9830b3000a7356ff16a52d146dd205f71ada4c9";
          const privateKey = "0xad5ad1790fc16fe8e1d19145362307bcd0b0ca78214fda6642eedf537d6e8331";
          const privatekey_slice = privateKey.substring(2, privateKey.length);
          const Buffer_privatekey = Buffer.from(privatekey_slice.toString(), 'hex');
          

          const tx = await new EthereumTx(response.data.data.raw, { chain: 4 })
          tx.sign(Buffer_privatekey);
          const rawTx = await `0x${tx.serialize().toString('hex')}`;
          const data_temp = await {
            ...response.data.data,
            raw: rawTx,
            images: imageList
          };
          console.log(data_temp)
          axios.post(LINK_API.CHECKIN, data_temp)
            .then(res => {
              if (res.data.errorCode === 1) {
                this.setState({
                  alert: (
                    <SweetAlert
                      success
                      style={{ display: "block" }}
                      title="Thành công"
                      onConfirm={() => this.handleDirect()}
                      onCancel={() => this.hideAlert()}
                      showConfirm={true}
                      closeOnClickOutside={false}
                    >
                    </SweetAlert>
                  )
                })
              } else {
                this.setState({
                  alert: (
                    <SweetAlert
                      error
                      style={{ display: "block" }}
                      title="Thất bại"
                      onConfirm={() => this.hideAlert()}
                      onCancel={() => this.hideAlert()}
                      showConfirm={true}
                      closeOnClickOutside={false}
                    >
                      Vui lòng thử lại!
                    </SweetAlert>
                  )
                })
              }
            })
            .catch(err => {
              this.setState({
                alert: (
                  <SweetAlert
                    error
                    style={{ display: "block" }}
                    title="Thất bại"
                    onConfirm={() => this.hideAlert()}
                    onCancel={() => this.hideAlert()}
                    showConfirm={true}
                    closeOnClickOutside={false}
                  >
                    Vui lòng thử lại!
                  </SweetAlert>
                )
              })
            })
        } else {
          this.setState({
            alert: (
              <SweetAlert
                error
                style={{ display: "block" }}
                title="Thất bại"
                onConfirm={() => this.hideAlert()}
                onCancel={() => this.hideAlert()}
                showConfirm={true}
                closeOnClickOutside={false}
              >
                Vui lòng thử lại!
              </SweetAlert>
            )
          })
        }
      })
      .catch(err => {
        console.log(err)
        this.setState({
          alert: (
            <SweetAlert
              error
              style={{ display: "block" }}
              title="Thất bại"
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              showConfirm={true}
              closeOnClickOutside={false}
            >
              Vui lòng thử lại!
            </SweetAlert>
          )
        })
      })
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <img alt="logo" src={"https://res.cloudinary.com/agridential/image/upload/v1583393051/images/logo_vbc_bn8cog.png"} className={classes.logo} />
        </div>
        <div style={{ width: "100%", textAlign: "center", display: "flex", flexFlow: "column", alignItems: "center" }}>
          <p className={classes.title1}>{this.state.name}</p>
          <p className={classes.title2}>{this.state.id}</p>
          <Webcam
            screenshotFormat="image/jpeg"
            ref={this.webcamRef}
            width={400}
            videoConstraints={this.state.videoConstraints}
          />
          <Button color="info" onClick={this.handleCapture}>Check in</Button>
          <div className={classes.link}>
            <a href="https://vietnamblockchain.asia/" className={classes.address}>www.vietnamblockchain.asia</a>
          </div>
        </div>
        {this.state.alert}
      </div>
    )
  }
}
const mapStateToProps = (state) => {
  return {
    checkIn: state.checkIn,
    id: state.id.id,
    overview: state.overview
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
    getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
    cleanStore: () => dispatch({ type: "CLEAN_STORE" })
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Scan)));