import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import EN from "assets/img/flags/GB.png";
import VN from "assets/img/flags/VN.png";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import strings from "constants/strings";
import PropTypes from "prop-types";
import React from "react";
import cookie from 'react-cookies';
import { withRouter } from 'react-router-dom';
import HeaderLookupStyle from "./HeaderLookupStyle.jsx";






const language = ["vi", "en"];
const language_img = {
  "vi": VN,
  "en": EN
}


class HeaderLookup extends React.Component {
 constructor(props) {
    super(props);
    this.state = {
      open: false,
      openDialog: false,
      gln: null,
      language: cookie.load("language")?cookie.load("language"):'vi',
      //language: 'vi',
      openLanguageMenu: false,
      anchorEl: null,
    };
    this.routeChange = this.routeChange.bind(this);
    this.routeScan = this.routeScan.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleLanguageItemClick = this.handleLanguageItemClick.bind(this);
    this.handleLanguageMenu = this.handleLanguageMenu.bind(this);
    this.handleLanguageMenuClose = this.handleLanguageMenuClose.bind(this);
  }

 async componentDidMount() {
    const lan = cookie.load("language");
    //console.log("lan", lan)
    //await this.setState({ language: lan })
    if (lan) {
      strings.setLanguage(lan);
      this.setState({ language: lan })
    }
    else {
      this.setState({ language: strings.getLanguage() })
    }
  }

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  handleLanguageMenu = event => {
    this.setState({ openLanguageMenu: !this.state.openLanguageMenu, anchorEl: event.currentTarget });
  };

  handleLanguageMenuClose = event => {
    this.setState({ openLanguageMenu: false });
  };

  handleLanguageItemClick = async (event, index) => {
    //console.log(index);
    await this.setState({ language: index });
    this.setState({ openLanguageMenu: false });
    await cookie.save('language', index, { path: '/' });
   window.location.reload();
  };

  onChange(e) {
    this.setState({
      gln: e.target.value
    })
  }

  routeChange() {
    if (!this.state.gln) return;
    let path = '/lookup/' + this.state.gln;
    this.props.history.push(path);
    console.log("Check")
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };
  handleClose = () => {
    this.setState({ open: false });
  };
  routeScan() {
    let path = '/scan';
    this.props.history.push(path);
  }
  render() {
    const { classes } = this.props;

    const AGRHEADER = "https://res.cloudinary.com/agridential/image/upload/c_scale,w_239/v1570694727/Logo/agd_black_text_aqarfl.png";
    const VBC = "https://res.cloudinary.com/agridential/image/upload/c_scale,w_272/v1570695532/Logo/vbcblack_voirvu.png";
    strings.setLanguage(this.state.language);

    const renderLanguageMenu = (
      <Menu
        anchorEl={this.state.anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={this.state.openLanguageMenu}
        onClose={this.handleLanguageMenuClose}
        style={{ marginLeft: "16px", marginTop: "33px" }}
      >
        {language.map((option, index) => (
          <MenuItem
            key={option}
            //   disabled={index === }
            selected={index === this.state.selectedIndex}
            onClick={event => this.handleLanguageItemClick(event, option)}
          >
            <img
              src={language_img[option]}
              width="23"
              height="17"
              alt=""
            />
          </MenuItem>
        ))}

      </Menu>
    );
    return (
      <div>
        <GridContainer className="HeaderPage">
          <GridItem xs={4} sm={4} md={3} >
            <img className="logoVBCHeader" src={VBC} alt=""/>
          </GridItem>
          <GridItem xs={5} sm={5} md={4}>
            <img className="logoAgriHeader" src={AGRHEADER} alt=""/>
          </GridItem>
          <GridItem xs={3} sm={3} md={3} className="poslang" >
            <Button className={classes.languageButton}>
              <img
                src={language_img[this.state.language]}
                height="24"
                className="languageImg"
                onClick={this.handleLanguageMenu}
                alt=""
              />
            </Button>
          </GridItem>
        </GridContainer>
        {renderLanguageMenu}
      </div>
    );
  }
}

HeaderLookup.propTypes = {
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool
};

export default withStyles(HeaderLookupStyle)(withRouter(HeaderLookup));
