const Sample_Coffee = {
    vi: {
        overview: {
            "Loại cà phê": "MaYaCa GOOD INSIDE GI",
            "Đặc điểm": "Lên men 2 lần",
            "Khối lượng tịnh": "250gram",
            "Phương pháp sơ chế": "Natural Longtime Processing, double fermentation",
            "Phương pháp rang": "Rang mộc - không tẩm",
            "Hạn sử dụng": "12 tháng, có hương vị và mùi thơm ngon nhất trong 2 tuần đầu sau khi mở gói.",
            // [strings.production_standard]: this.data.treeInfo.standard,
            "Tạp lẫn": "0%",
            "Thành phần": "40% Arabica, 60% Robusta",
            "Quy trình sản xuất": "100% thiên nhiên",
            "Kích thước": "9*4.8*18.5cm",
            "Hương vị, mùi thơm": "Hương vị sẽ tuyệt vời nếu kết hợp với một ít quinic acid bright, trái cây, mật ong và chocolate đắng, cafein vừa",
            "Dùng cho": "Dùng cho máy pha cà phê, hoặc xay thành bột để lọc, pha phin hoặc syphon",
            "Bảo quản": "Bảo quản ở nơi thoáng mát, tránh ánh nắng trực tiếp. Các sản phẩm cà phê MaYaCa nguyên chất là tốt nhất cho sức khỏe của bạn và gia đình"
        },
        banner: require("../assets/img/bg-img/bannermayaca.png"),
        logo: [
            {
                original: 'https://res.cloudinary.com/agridential/image/upload/v1562226685/Products/Mayaca/GI_3_final_poznsd.png',
                thumbnail: 'https://res.cloudinary.com/agridential/image/upload/v1562226685/Products/Mayaca/GI_3_final_poznsd.png',
                className: "imageSlider"
              },
              {
                original: 'https://res.cloudinary.com/agridential/image/upload/v1562226685/Products/Mayaca/GI_2_final_yc6m9b.png',
                thumbnail: 'https://res.cloudinary.com/agridential/image/upload/v1562226685/Products/Mayaca/GI_2_final_yc6m9b.png',
                className: "imageSlider"
              },
              {
                original: 'https://res.cloudinary.com/agridential/image/upload/v1562232110/Products/Mayaca/GI_zbx1fx.png',
                thumbnail: 'https://res.cloudinary.com/agridential/image/upload/v1562232110/Products/Mayaca/GI_zbx1fx.png',
                className: "imageSlider"
              },
        ],
        video: "https://www.youtube.com/embed/YPbC4Gln6kM",
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.8303172027377!2d108.17260789999999!3d16.0742927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314218e4027d27b9%3A0xb86eae6f7328a846!2sC%C3%B4ng+ty+TNHH+MTV+MaYaCa!5e0!3m2!1svi!2s!4v1560419852317!5m2!1svi!2s",
        certificate: [
            {
                title: "Chứng nhận vệ sinh an toàn thực phẩm",
                image: require("../assets/img/certs/antoancoffee.jpg")
            },

        ],
        dateBegin: ""
    },
    en: {
        overview: {
            "Type of coffee": "Bean/Powder",
            "Characteristics": "Ferment twice",
            "Net weight": "250gram",
            "Processing method": "Natural Longtime Processing, double fermentation",
            "Roasting method": "Rustic roast - Raw 7",
            "Expiried date": "12 months. Best flavor and aroma in 2 weeks when open the package",
            "Mix": "0%",
            "Ingredient": "40% Arabica, 60% Robusta",
            "Production process": "100% natural",
            "Size": "9*4.8*18.5cm",
            "Flavor, aroma": "Good flavorfull with a litte quinic acid bright, fruity, honey sweet, dark chocolate, medium cafein, last after taste",
            "Use for": "Coffee machine or grind beans into powder for filter, drip, shyphon,...",
            "Preservation": "Preserved in a cool dry place, avoid direct sunlight MaYaCa pure coffee products are best for your health and your family!"
        },
        logo: [
            {
                original: 'https://res.cloudinary.com/agridential/image/upload/v1562226685/Products/Mayaca/GI_3_final_poznsd.png',
                thumbnail: 'https://res.cloudinary.com/agridential/image/upload/v1562226685/Products/Mayaca/GI_3_final_poznsd.png',
                className: "imageSlider"
              },
              {
                original: 'https://res.cloudinary.com/agridential/image/upload/v1562226685/Products/Mayaca/GI_2_final_yc6m9b.png',
                thumbnail: 'https://res.cloudinary.com/agridential/image/upload/v1562226685/Products/Mayaca/GI_2_final_yc6m9b.png',
                className: "imageSlider"
              },
              {
                original: 'https://res.cloudinary.com/agridential/image/upload/v1562232110/Products/Mayaca/GI_zbx1fx.png',
                thumbnail: 'https://res.cloudinary.com/agridential/image/upload/v1562232110/Products/Mayaca/GI_zbx1fx.png',
                className: "imageSlider"
              },
        ],
        banner: require("../assets/img/bg-img/bannermayaca.png"),
        video: "https://www.youtube.com/embed/YPbC4Gln6kM",
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.8303172027377!2d108.17260789999999!3d16.0742927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314218e4027d27b9%3A0xb86eae6f7328a846!2sC%C3%B4ng+ty+TNHH+MTV+MaYaCa!5e0!3m2!1svi!2s!4v1560419852317!5m2!1svi!2s",
        certificate: [
            {
                title: "Food safety and hygiene certification",
                image: require("../assets/img/certs/antoancoffee.jpg")
            }

        ],
        dateBegin: ""
    },



}

const Sample_Garlic = {
    overview: {
        "Thành phần dinh dưỡng": "Tỏi đen có giá trị dinh dưỡng và sinh học rất cao. Hàm lượng cacbonhydrate, lipit, protein ở trạng thái cân bằng và dễ hấp thu, có đầy đủ 18 acid amin. Hàm lượng chất chống oxy hoá cao hơn tỏi tươi nhiều lần, đặc biệt là polyphenol và Sally cysteine…",
        "Mùi vị": "Tỏi đen ngọt, có vị thơm nhẹ, dễ ăn, đặc biệt không còn mùi hăng của tỏi",
        "Hạn sử dụng": "24 tháng",
        "Hướng dẫn sử dụng": "Dùng trực tiếp hoặc kết hợp với các món ăn hàng ngày",
    },
    banner: require("../assets/img/bg-img/bannertoiden.jpg"),
    logo: require("../assets/img/products/toilinhdan.png"),
    video: "https://www.youtube.com/embed/USziMJHHz0g",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.0402001773045!2d106.67602571526055!3d10.808233061560106!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317528df10a5c0dd%3A0xb2f4a7e71de1fc69!2zQ3R5IFThu49pIMSQZW4gTGluaCDEkGFuIENoaSBOaMOhbmggSENN!5e0!3m2!1svi!2s!4v1559861670081!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận vệ sinh an toàn thực phẩm",
            image: require("../assets/img/certs/toilinhdanh.png")
        },
    ],
    dateBegin: ""

}

const image = require("../assets/img/products/logothuonghieu.png");
const Sample_Biophap = {
    overview: {
        "Khối lượng": "85gram",
        "Hướng dẫn sử dụng": "+ Dùng như một loại gia vị để tăng thêm hương thơm và độ nồng cho món ăn (Khi kết hợp với hạt tiêu, sự hấp thụ của nghệ có thể được gia tăng tới hơn 2,000 lần). \n + Kết hợp với các món rau xào. \n + Dùng thay phẩm màu công nghiệp để tạo màu sắc và mùi hương quyến rũ cho món ăn. \n + Dùng như một loại hương liệu cho các loại sinh tố. \n + Dùng để pha trà (kết hợp giữa bột nghệ, mật ong và sữa sẽ mang lại cho bạn một công thức uống thực sự thư giãn với hương vị thơm nồng nhẹ nhàng và ấm nóng từ hương vị đến màu sắc.",
        "Tiêu chuẩn": image,
    },
    banner: require("assets/img/bg-img/bannerbiophap.jpg"),
    logo: require("../assets/img/products/biophap.png"),
    standard: image,
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3865.261742617298!2d108.01370801527737!3d14.35425858691319!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x316bffbc78ebd245%3A0x3a7458ab76355870!2sBIOPHAP+Ltd+-+Office!5e0!3m2!1svi!2s!4v1561347588849!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận USDA ORGANIC",
            image: require("../assets/img/certs/biophapcert1.png")
        },
        {
            title: "Certification Programmes for Fair Trade & responsible supply chains and Corporate Social Responsibility            ",
            image: require("../assets/img/certs/biophapcert2.png")
        },
    ],
    dateBegin: ""

}

const Sample_Box = {
    overview: {
        "Quy cách": "20 chai/thùng",
    },
    banner: "",
    logo: "",
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3857.3856187606802!2d107.94085211528021!3d14.803600276037217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x316bdbdd66281de1%3A0xba2fe6df01dec24!2sThanh+T%C3%A2m!5e0!3m2!1svi!2s!4v1561704915649!5m2!1svi!2s",
    certificate: [],
    dateBegin: ""

}

const Sample_Garden = {
    overview: {
    },
    banner: "",
    logo: require("../assets/img/products/vuondangsam.jpg"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3857.3856187606802!2d107.94085211528021!3d14.803600276037217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x316bdbdd66281de1%3A0xba2fe6df01dec24!2sThanh+T%C3%A2m!5e0!3m2!1svi!2s!4v1561704915649!5m2!1svi!2s",
    certificate: [
        {
            title: "Kết quả thử nghiệm",
            image: require("../assets/img/certs/ruoidangsam.jpg")
        },
    ],
    dateBegin: ""
}

const Sample_Alcohol = {
    overview: {
    },
    banner: "",
    logo: require("../assets/img/products/ruousam1.png"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3857.3856187606802!2d107.94085211528021!3d14.803600276037217!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x316bdbdd66281de1%3A0xba2fe6df01dec24!2sThanh+T%C3%A2m!5e0!3m2!1svi!2s!4v1561704915649!5m2!1svi!2s",
    certificate: [
        {
            title: "Kết quả thử nghiệm",
            image: require("../assets/img/certs/cert_myxuong_spnongnghieptieubieu.jpg")
        },
    ],
    dateBegin: ""
}

const Sample_Fruit = {
    vi: {
        overview: {
            "Hạn sử dụng": "7 ngày sau đóng gói",
            "Tiêu chuẩn sản xuất": "VietGAP",
            "Vị Fruit": ""
        },
        banner: "",
        logo: require("../assets/img/products/xoaicatchu.png"),
        standard: "",
        video: "https://www.youtube.com/embed/pen8R3crwXk",
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.1415185591504!2d105.71625895043822!3d10.410332992534073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310a6333e3a6a9b1%3A0xdc428219b335bb4!2zSOG7o3AgVMOhYyBYw6MgWG_DoGkgTeG7uSBYxrDGoW5n!5e0!3m2!1svi!2s!4v1554266139137!5m2!1svi!2s",
        certificate: [
            {
                title: "Chứng nhận VietGAP",
                image: require("../assets/img/certs/cert_htxmyxuong_vietgap.jpg")
            },
            {
                title: "Chứng nhận sản phẩm nông nghiệp tiêu biểu 2014",
                image: require("../assets/img/certs/cert_myxuong_spnongnghieptieubieu.jpg")
            }
        ],
        dateBegin: ""
    },
    en: {
        overview: {
            "Day of expire": "7 days after producing",
            "Production Standard": "VietGAP",
            "Vị Fruit": ""
        },
        banner: "",
        logo: require("../assets/img/products/xoaicatchu.png"),
        standard: "",
        video: "https://www.youtube.com/embed/pen8R3crwXk",
        location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.1415185591504!2d105.71625895043822!3d10.410332992534073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310a6333e3a6a9b1%3A0xdc428219b335bb4!2zSOG7o3AgVMOhYyBYw6MgWG_DoGkgTeG7uSBYxrDGoW5n!5e0!3m2!1svi!2s!4v1554266139137!5m2!1svi!2s",
        certificate: [
            {
                title: "VietGAP Certificate",
                image: require("../assets/img/certs/cert_htxmyxuong_vietgap.jpg")
            },
            {
                title: "Certification of typical agricultural products 2014",
                image: require("../assets/img/certs/cert_myxuong_spnongnghieptieubieu.jpg")
            }
        ],
        dateBegin: ""
    }
    
}


const Sample_Herb = {
    overview: {
        "Loại": "Loại 1",
        "Kích cỡ": "Cọng dài 30-40cm",
        "Kích thước": "Đường kính cọng 1-2mm",
        "Đơn vị bán": "150g/túi",
        "Hạn sử dụng": "4 ngày kể từ ngày đóng gói",
        "Khác": "Bao bì đóng gói đục lỗ 4 góc",
    },
    banner: require("../assets/img/products/HTXrau.jpg"),
    logo: require("../assets/img/products/rauthom.png"),
    standard: "",
    video: "https://www.youtube.com/embed/nColW32zFvs",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3921.2849123859005!2d106.56929611479956!3d10.634959992417599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175336494ed1b0b%3A0xfd5a05bc731bff3d!2zxJDGsOG7nW5nIOG6pHAgNCwgTG9uZyBBbg!5e0!3m2!1sen!2s!4v1562740578612!5m2!1sen!2s",
    certificate: [],
    dateBegin: 1571787925000
}

const Sample_Spinach = {
    overview: {
        "Loại": "Loại 1",
        "Kích cỡ": "Cọng dài 30-40cm",
        "Kích thước": "Đường kính cọng 1-2mm",
        "Đơn vị bán": "300g/túi",
        "Hạn sử dụng": "4 ngày kể từ ngày đóng gói",
        "Khác": "Bao bì đóng gói đục lỗ 4 góc",
    },
    banner: require("../assets/img/products/HTXrau.jpg"),
    logo: require("../assets/img/products/raumuong.png"),
    standard: "",
    video: "https://www.youtube.com/embed/nColW32zFvs",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3921.2849123859005!2d106.56929611479956!3d10.634959992417599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175336494ed1b0b%3A0xfd5a05bc731bff3d!2zxJDGsOG7nW5nIOG6pHAgNCwgTG9uZyBBbg!5e0!3m2!1sen!2s!4v1562740578612!5m2!1sen!2s",
    certificate: [],
    dateBegin: 1571787925000
}

const Sample_Honey = {
    overview: {
        "Thành phần": "Mật ong nguyên chất 99.9%, hương liệu giống tự nhiên dùng cho thực phẩm 0.1%",
        "Hạn sử dụng": "3 năm",
        "Thể tích thực": "500ml",
        "Hướng dẫn sử dụng": "Dùng trực tiếp, ướp gia vị hoặc pha với nước ấm vào mỗi buổi sáng, làm bánh hoặc pha cà phê, sinh tố, ....",
        "Bảo quản": "Nơi khô ráo, thoáng mát",
    },
    banner: require("../assets/img/bg-img/bannermatong.jpg"),
    logo: require("../assets/img/products/matongnani.png"),
    standard: "",
    video: "https://www.youtube.com/embed/XKz6AaPB2po",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3851.818789992149!2d108.80311271528227!3d15.113312668356567!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3169ad2a84bd7a6b%3A0x6eb9a9957397b299!2zMjIsIDY1IEzDqiBM4bujaSwgQ2jDoW5oIEzhu5ksIFF14bqjbmcgTmfDo2ksIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1559985667989!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận sản phẩm công nghiệp nông thôn tiêu biểu",
            image: require("../assets/img/certs/chungnhannani1.png")
        },
        {
            title: "Bằng khen Giải nhất ý tưởng khởi nghiệm năm 2017",
            image: require("../assets/img/certs/chungnhannani2.jpg")
        }
    ],
    dateBegin: ""
}

const Sample_Incense = {
    overview: {
        "Trọng lượng": "+ 60gr (30 viên đạn , thời gian cháy 15-20 phút/viên) \n + 110 gr ( 36 – 38 viên lớn , thời gian cháy 20-25 phút/viên",
        "Thành phần": " + Bột trầm từ cây dó bầu \n + Keo thực vật (keo cây bời rời)",
        "Công dụng": "+ 100% nguồn gốc từ thiên nhiên không gây hại sức khỏe con người. \n + Hương thơm ấm áp, nhẹ diệu. \n + Sử dụng trong tôn giáo, nghi lễ thờ cúng. \n + Sản phẩm để càng lâu càng thơm.",
        "Bao bì": "Hộp nhựa, nhãn vàng .",
        "Sản phẩm đạt chuẩn": "TCCS01:2015/CT"
    },
    banner: require("../assets/img/bg-img/bannerphuclinh.png"),
    logo: require("../assets/img/products/tramhuongphuclinh.png"),
    standard: "",
    video: "https://www.youtube.com/embed/94VHQpa69kQ",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3833.9445969576827!2d108.21163901528882!3d16.06836454372815!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x314219bd56699089%3A0x81382bc3fbc75742!2zdHLhuqdtIGjGsMahbmcgcGjDumMgbGluaA!5e0!3m2!1svi!2s!4v1560071621785!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận tích cực tham gia APEC 2017",
            image: require("../assets/img/certs/chungnhantramhuong1.jpg")
        },
        {
            title: "Chứng nhận sản phẩm tốt nhất tại APEC 2017",
            image: require("../assets/img/certs/chungnhantramhuong2.jpg")
        }
    ],
    dateBegin: ""
}

const Sample_Kimchi = {
    overview: {
        "Loại kim chi": "Kim chi cải thảo Đông Du",
        "Đặc điểm": "Không dùng chất bảo quản, lên men tự nhiên",
        "Khối lượng tịnh": "500 gram",
        "Hạn sử dụng": "2 tháng sau khi đóng gói",
        "Tạp lẫn": "0%, không sử dụng chất bảo quản",
        "Thành phần": "Cải thảo 80%, Cà rốt 10%, Hành lá, Ớt bột, Tỏi 10%",
        "Quy trình sản xuất": "100% thiên nhiên"
    },
    banner: require("../assets/img/bg-img/bannerkimchidongdu.jpg"),
    logo: require("../assets/img/products/kimchi.png"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3903.675329922861!2d108.44205601384621!3d11.927668140151829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31711338a2fe85af%3A0xdc213e72b442b7bb!2zxJDGsOG7nW5nIFTDtCBIaeG6v24gVGjDoG5oLCBQaMaw4budbmcgMywgVHAuIMSQw6AgTOG6oXQsIEzDom0gxJDhu5NuZywgVmlldG5hbQ!5e0!3m2!1sen!2s!4v1562746383400!5m2!1sen!2s",
    certificate: [

    ],
    dateBegin: ""
}

const Sample_Mango = {
    overview: {
        "Hạn sử dụng": "7 ngày sau đóng gói",
        "Tiêu chuẩn sản xuất": "VietGAP",
    },
    banner: require("../assets/img/bg-img/bannertankhanhdong.jpg"),
    logo: require("../assets/img/products/xoaisadec.jpg"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31399.023334203735!2d105.71257884949334!3d10.351645594242937!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310a7c524062cf17%3A0xd76d36efeb81c7e9!2zVMOibiBLaMOhbmggxJDDtG5nLCBUcC4gU2EgxJDDqWMsIMSQ4buTbmcgVGjDoXAsIFZp4buHdCBOYW0!5e0!3m2!1svi!2s!4v1564111604181!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận VietGAP",
            image: require("../assets/img/certs/chungnhanxoaisadec.png")
        },
    ],
    dateBegin: ""
}

const Sample_Melon = {
    overview: {
        "Thời gian từ khi xuống giống - thu hoạch": "60 ngày",
        "Đặc tính giống": "Trái tròn oval, vỏ xanh, ruột cam. Vỏ mỏng, thịt mềm, thơm, Brix 14+",
        "Trọng lượng": "1.5 kg",
        "Nhược điểm": "Dễ bị thối nhũn đít trái"
    },
    banner: require("../assets/img/products/bannersongmai.png"),
    logo: require("../assets/img/products/dualuoi.jpg"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7850.29089127972!2d105.47274607112763!3d10.33024317026718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1zxJDDtG5nIFRo4bqhbmggQSwgUC5N4bu5IFRo4bqhbmgsIFRQLkxvbmcgWHV5w6puLCB04buJbmggQW4gR2lhbmc!5e0!3m2!1sen!2s!4v1563767980430!5m2!1sen!2s",
    certificate: [
        {
            title: "Chứng nhận VietGAP",
            image: require("../assets/img/certs/songmai_certificate.jpg")
        },
    ],
    dateBegin: ""
}

const Sample_Rice = {
    overview: {
        "Loại gạo": "Gạo Sạch Tràng Chim",
        "Đặc điểm": "Hạt gạo nhỏ, dài, màu trắng trong, mùi thơm đặc trưng, cho cơm dẻo, xốp, mềm, ngọt cơm",
        "Khối lượng tịnh": "3kg",
        "Hạn sử dụng": "1 năm sau khi đóng gói",
        "Thành phần": "100% gạo thơm chất lượng cao",
        "Quy trình sản xuất": "Không dùng thuốc BVTV hóa học. Quá trình canh tác sử dụng phân bón hữu cơ và thuốc BVTV có nguồn gốc vi sinh. Sản phẩm không dùng hóa chất tạo mùi."
    },
    banner: "",
    logo: require("../assets/img/products/gaodongthap.jpg"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31386.679046274217!2d105.59782823716537!3d10.474244922441551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310a361aa2e1c483%3A0x22da3197eebbe81!2zUUwzMCwgTeG7uSBUw6JuLCBUcC4gQ2FvIEzDo25oLCDEkOG7k25nIFRow6FwLCBWaWV0bmFt!5e0!3m2!1sen!2s!4v1564562979885!5m2!1sen!2s",
    certificate: [
        {
            title: "Top 100 thương hiệu xanh phát triển",
            image: require("../assets/img/certs/top100gdt.jpg")
        },
        {
            title: "Chứng nhận FDA của Mỹ",
            image: require("../assets/img/certs/fda.jpg")
        },
    ],
    dateBegin: ""
}

const Sample = {
    overview: {
    },
    banner: "",
    logo: require("../assets/img/logo_vbc.png"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3919.6171538277135!2d106.65410231526026!3d10.76396026236589!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31752948138337ad%3A0xeb0ed9325dfa16eb!2sInfinity+Blockchain+Labs!5e0!3m2!1svi!2s!4v1560907751745!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận",
            image: require("../assets/img/certs/vbc.png")
        },
    ],
    dateBegin: ""
}

const Sample_Tree = {
    overview: {
        "Giống": "Xoài Cao Lãnh",
        "Tuổi cây": "16 tuổi",
        "Kích thước": "Chiều cao 7m - Đường kính tán 7m",
        "Sản lượng": "70 kg/năm",
        "Tiêu chuẩn sản xuất": "VietGAP"
    },
    banner: "",
    logo: require("../assets/img/products/cayxoainhatoi.jpg"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3924.1415185591504!2d105.71625895043822!3d10.410332992534073!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x310a6333e3a6a9b1%3A0xdc428219b335bb4!2zSOG7o3AgVMOhYyBYw6MgWG_DoGkgTeG7uSBYxrDGoW5n!5e0!3m2!1svi!2s!4v1554266139137!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận VietGAP",
            image: require("../assets/img/certs/cert_htxmyxuong_vietgap.jpg")
        },
        {
            title: "Chứng nhận sản phẩm nông nghiệp tiêu biểu 2014",
            image: require("../assets/img/certs/cert_myxuong_spnongnghieptieubieu.jpg")
        },
    ],
    dateBegin: ""
}
const Sample_Tungho = {
    overview: {
        "Khối lượng tịnh": "bó 350g",
        "Hạn sử dụng": "4 ngày"
    },
    banner: require("../assets/img/bg-img/bannercachua.png"),
    logo: require("../assets/img/products/tano.png"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d31251.962274344613!2d108.34437824983485!3d11.73011683687155!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x317146d1f159648b%3A0xb8e5113f2d5d59c7!2zdHQuIExpw6puIE5naMSpYSwgxJDhu6ljIFRy4buNbmcsIEzDom0gxJDhu5NuZywgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1562728918439!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận VietGAP",
            image: require("../assets/img/certs/chungnhanVGcachua.jpg")
        },
        {
            title: "Chứng nhận vệ sinh an toàn thực phẩm",
            image: require("../assets/img/certs/cachuabi.jpg")
        },
    ],
    dateBegin: ""
}

const Sample_Tomato = {
    overview: {
        "Khối lượng tịnh" : "300g"
    },
    banner: require("../assets/img/bg-img/bannercachua.png"),
    logo: require("../assets/img/products/cachuabivang.png"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3906.3644819579695!2d108.36819521526411!3d11.739352643889921!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3171412dea26f22f%3A0xa4413f8eecebd073!2zTMOqIEjhu5NuZyBQaG9uZywgxJDhu6ljIFRy4buNbmcsIEzDom0gxJDhu5NuZywgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1565083077318!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận Chuỗi thực phẩm an toàn",
            image: require("../assets/img/certs/chungnhanchuoiantoan.jpg")
        },
        {
            title: "Chứng nhận VietGAP",
            image: require("../assets/img/certs/chungnhanVGcachua.jpg")
        },
        {
            title: "Chứng nhận vệ sinh an toàn thực phẩm",
            image: require("../assets/img/certs/cachuabi.jpg")
        },
    ],
    dateBegin: ""
}

const Sample_Salad = {
    overview: {
    },
    banner: require("../assets/img/bg-img/bannergreenfarm.jpg"),
    logo: require("../assets/img/products/xalachthuycanh.jpg"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3917.3656366445507!2d107.25400041526103!3d10.935728459222252!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174f8cd96363a0d%3A0x7abf45966447eec5!2zMjMgVHLhuqduIFbEg24gVGhpLCBYdcOibiBUaGFuaCwgTG9uZyBLaMOhbmgsIMSQ4buTbmcgTmFpLCBWaeG7h3QgTmFt!5e0!3m2!1svi!2s!4v1565856428536!5m2!1svi!2s",
    certificate: [
        // {
        //     title: "Chứng nhận Chuỗi thực phẩm an toàn",
        //     image: require("../../assets/img/certs/chungnhanchuoiantoan.jpg")
        // },
    ],
    dateBegin: ""
}
const Sample_Mushroom = {
    overview: {
    },
    banner: require("../assets/img/bg-img/bannervinhphuc.jpg"),
    logo: require("../assets/img/products/namrom.jpg"),
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62661.74734382535!2d106.8952795!3d11.0116527!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174dd6eaaaaaaab%3A0x68365297d8f0b3a!2z4bqlcCBWw6Bt!5e0!3m2!1svi!2s!4v1565861767528!5m2!1svi!2s",
    certificate: [
        // {
        //     title: "Chứng nhận Chuỗi thực phẩm an toàn",
        //     image: require("../../assets/img/certs/chungnhanchuoiantoan.jpg")
        // },
    ],
    dateBegin: ""
}

const Sample_Product = {
    overview: {
    },
    banner: "",
    logo: "",
    standard: "",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d62661.74734382535!2d106.8952795!3d11.0116527!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174dd6eaaaaaaab%3A0x68365297d8f0b3a!2z4bqlcCBWw6Bt!5e0!3m2!1svi!2s!4v1565861767528!5m2!1svi!2s",
    certificate: [
        // {
        //     title: "Chứng nhận Chuỗi thực phẩm an toàn",
        //     image: require("../../assets/img/certs/chungnhanchuoiantoan.jpg")
        // },
    ],
    dateBegin: ""
}

const Sample_Longan = {
    overview: {
       "Xuất xứ" : "Việt Nam",
       "Hướng dẫn sử dụng" : "Ăn trực tiếp",
       "Hướng dẫn bảo quản" : "Ngăn mát tủ lạnh"
    },
    banner: "",
    logo: require("../assets/img/products/nhanxuong.jpg"),
    //     {
    //         original: require("../../assets/img/products/nhanxuong.jpg"),
    //         thumbnail:  require("../../assets/img/products/nhanxuong.jpg"),
    //         className: "imageSlider"
    //       }
    // ],
    
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62725.59389936648!2d107.46205989484906!3d10.707493646229954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175b1ceb59d8bcb%3A0xa2d6b06e54d03c17!2zSMOyYSBIaeG7h3AsIFh1ecOqbiBN4buZYywgQsOgIFLhu4thIC0gVsWpbmcgVMOgdSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1570163560356!5m2!1svi!2s",
    certificate: [
        {
            title: "Chứng nhận VietGAP",
            image: require("../assets/img/certs/nhan2.jpg")
        },
        {
            title: "Biên bản đánh giá quy trình sản xuất nông nghiệp tốt",
            image: require("../assets/img/certs/nhan1.jpg")
        },
        {
            title: "Phiếu kết quả thử nghiệm",
            image: require("../assets/img/certs/nhan3.jpg")
        },
    ],
    dateBegin: ""
}

const Sample_Xetang= {
    overview: {
        "Giới thiệu": "Panzerkampfwagen IV (Pz.Kpfw. IV) thường được gọi là Panzer IV là một chiếc xe tăng hạng trung của Đức Quốc xã được thiết kế vào cuối những năm 1930 và được sử dụng rộng rãi trong Chiến tranh thế giới thứ hai",
        "Số kiểm tra": "Sd.Kfz.161"

    },
    banner: "",
    // logo:require("../../assets/img/products/sample_xetang.obj"),
    logo:"https://res.cloudinary.com/agridential/raw/upload/v1570595185/Products/Image3D/sample_xetang_ceyoob.obj",
    // logo: "https://res.cloudinary.com/agridential/raw/upload/v1570596246/Products/Image3D/xetang_ejdzoy.stl",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62725.59389936648!2d107.46205989484906!3d10.707493646229954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175b1ceb59d8bcb%3A0xa2d6b06e54d03c17!2zSMOyYSBIaeG7h3AsIFh1ecOqbiBN4buZYywgQsOgIFLhu4thIC0gVsWpbmcgVMOgdSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1570163560356!5m2!1svi!2s",
    certificate: [
    ],
    dateBegin: ""
}

const Sample_Xetang1= {
    overview: {
        "Giới thiệu": "Panzerkampfwagen IV (Pz.Kpfw. IV) thường được gọi là Panzer IV là một chiếc xe tăng hạng trung của Đức Quốc xã được thiết kế vào cuối những năm 1930 và được sử dụng rộng rãi trong Chiến tranh thế giới thứ hai",
        "Số kiểm tra": "Sd.Kfz.161"
    },
    
    banner: "",
    // logo:require("../../assets/img/products/sample_xetang.obj"),
    logo:"https://res.cloudinary.com/agridential/raw/upload/v1570595185/Products/Image3D/sample_xetang_ceyoob.obj",
    // logo: "https://res.cloudinary.com/agridential/raw/upload/v1570596246/Products/Image3D/xetang_ejdzoy.stl",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d62725.59389936648!2d107.46205989484906!3d10.707493646229954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3175b1ceb59d8bcb%3A0xa2d6b06e54d03c17!2zSMOyYSBIaeG7h3AsIFh1ecOqbiBN4buZYywgQsOgIFLhu4thIC0gVsWpbmcgVMOgdSwgVmnhu4d0IE5hbQ!5e0!3m2!1svi!2s!4v1570163560356!5m2!1svi!2s",
    certificate: [
    ],
    dateBegin: ""
}
const Sample_Lakeview= {
    overview: {
        "Giới thiệu": "De Heus Việt Nam trực thuộc tập đoàn De Heus, chuyên sản xuất thức ăn chăn nuôi chất lượng cao cho gia súc, gia cầm và thủy sản, hiện có 9 nhà máy và hệ thống các kho trung chuyển hoạt động trên khắp cả nước. De Heus hướng tới việc chủ động đáp ứng cho tất cả các nhu cầu của thị trường cũng như nhu cầu của khách hàng. Luôn nỗ lực mạnh mẽ trong việc cải tiến công nghệ và hiệu quả thức ăn chăn nuôi, De Heus cam kết rằng khách hàng của chúng tôi sẽ nhận được sự hỗ trợ kỹ thuật tốt nhất và kịp thời nhất cũng như giúp khách hàng cắt giảm chi phí để gia tăng hiệu quả chăn nuôi.",
    },
    
    banner: "",
    // logo:require("../../assets/img/products/sample_xetang.obj"),
    logo:"https://res.cloudinary.com/agridential/raw/upload/v1570595185/Products/Image3D/sample_xetang_ceyoob.obj",
    // logo: "https://res.cloudinary.com/agridential/raw/upload/v1570596246/Products/Image3D/xetang_ejdzoy.stl",
    video: "",
    location: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3917.495568538913!2d107.1337817!3d10.9258885!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3174fb7c5c5f491b%3A0x1419fbf3519a32c4!2zQ8O0bmcgdHkgVE5ISCBEZSBIZXVzIC0gQ04gxJDhu5NuZyBOYWk!5e0!3m2!1svi!2s!4v1583331050952!5m2!1svi!2s",
    certificate: [
    ],
    dateBegin: ""
}

export {
    Sample_Coffee,
    Sample_Garlic,
    Sample_Biophap,
    Sample_Box,
    Sample_Garden,
    Sample_Alcohol,
    Sample_Fruit,
    Sample_Herb,
    Sample_Spinach,
    Sample_Honey,
    Sample_Incense,
    Sample_Kimchi,
    Sample_Mango,
    Sample_Melon,
    Sample_Rice,
    Sample,
    Sample_Tree,
    Sample_Tungho,
    Sample_Tomato,
    Sample_Salad,
    Sample_Mushroom,
    Sample_Product,
    Sample_Longan,
    Sample_Xetang,
    Sample_Xetang1,
    Sample_Lakeview
}