import { withStyles } from '@material-ui/core/styles';
import GridItem from "components/Grid/GridItem.jsx";
import strings from 'constants/strings';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './infoStyles';
class LookupRice extends React.Component {
 
  render() {
    const VBC = "https://res.cloudinary.com/agridential/image/upload/c_scale,w_221/v1570694865/Logo/logo_vbc_kijy1j.png";
    const AGR = "https://res.cloudinary.com/agridential/image/upload/c_scale,w_200/v1570694729/Logo/agd_white_text_ofaasb.png";
    strings.setLanguage(strings.getLanguage());

    return (
      <div>
        <div className="footer-distributed">
          <GridItem xs={12} sm={12} md={5}>

            <img alt="VBC" className="logoAgri" src={VBC} />
            <p> </p>
            <p className="introduce">{strings.vbc_about}</p>
            <p className="phonenumber">0908 444 095</p>
            <div style={{ marginTop: "0px" }}>
              <p><a href="mailto:contact@vietnamblockchain.asia" className="addressvbc">contact@vietnamblockchain.asia</a></p>
            </div>
            <div style={{ marginTop: "14px" }}>
              <p className="addressvbc">{strings.vbc_address}</p>
            </div>

          </GridItem>
          <GridItem xs={12} sm={12} md={5}>

          <img alt="AGD" className="logoAgri" src={AGR} />
          <p> </p>
          <p className="introduce">{strings.agri_about}</p>
          <p className="weblink">Website: <a href="# " className="introduce" onClick={() => {
                  window.location.href = 'https://agridential.vn/contact-us/';
                  return null;
                }}>agridential.vn</a></p>
          {/* <p className="phonenumber">0908 444 095</p>
          <div style={{ marginTop: "0px" }}>
            <p><a href="mailto:contact@vietnamblockchain.asia" style={{ color: "#fff" }}>contact@vietnamblockchain.asia</a></p>
          </div>
          <div style={{ marginTop: "14px" }}>
            <p className="addressvbc">Tầng 4, Lữ Gia Plaza, 70 Lữ Gia, Phường 15, Quận 11, Tp.HCM</p>
          </div> */}

          </GridItem>
          {/* <GridItem xs={12} sm={12} md={5}>

            <p className="Company">COMPANY</p>
            <hr className="LineBreak" />
            <GridContainer style={{ marginTop: "31px" }}>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink">Trang chủ</p>
              </GridItem>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink">BlockChain</p>
              </GridItem>
            </GridContainer >
            <GridContainer style={{ marginTop: "20px" }}>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink">Sản phẩm</p>
              </GridItem>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink">Dịch vụ</p>
              </GridItem>
            </GridContainer>
            <GridContainer style={{ marginTop: "20px" }}>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink" onClick={() => {
                  window.location.href = 'https://agridential.vn/contact-us/';
                  return null;
                }}>Giải pháp</p>
              </GridItem>
              <GridItem xs={4} sm={12} md={3} >
                <p className="weblink" onClick={() => {
                  window.location.href = 'https://agridential.vn/contact-us/';
                  return null;
                }}>Giới thiệu</p>
              </GridItem>
            </GridContainer>


          </GridItem> */}
          {/* <GridItem xs={3} sm={3} md={3}>
    
    <p className="oursettler">OUR NEWSLETTER</p>
    <hr className="LineBreak"/>
    <GridContainer style={{marginTop: "30px"}}>
    <GridItem xs={12} sm={12} md={12} >
        <p className="textleft">Get in your inbox the latest News and Offers from Agridental</p>  
    </GridItem>
    </GridContainer >
    <GridContainer style={{marginTop: "20px"}}>
    <GridItem xs={12} sm={12} md={12} >
    <TextField
      id="outlined-dense"
      margin="dense"
      variant="outlined"
      placeholder="Your Email Address"
      className="EmailInput"
      label=""
      InputProps={{
        classes: {
          notchedOutline: classes.notchedOutline,
          input: classes.multilineColor
        }
      }}
      
    />
    </GridItem>
    </GridContainer>
   </GridItem> */}
        </div>
         <div className="breakarea">
         <GridItem xs={12} sm={12} md={12} style={{display:"flex", justifyContent:"center"}}>
          <hr className="LineBreakDown"/>
          </GridItem>
          <div style={{marginTop:"20px",marginBottom:"20px !important", textAlign:"center",display:"flex", justifyContent:"center"}}>
            <p className="Copyright">Copyright © 2019 All Rights Reserved.</p>
          </div>
         </div>
         </div>
    );
  }
}

LookupRice.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(LookupRice);