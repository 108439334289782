import withStyles from "@material-ui/core/styles/withStyles";
// import emptybox from 'assets/img/emptybox.png'
import Button from "components/CustomButtons/Button.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { IMG_LOAD } from 'constants/index.js';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import HeaderLookupEducation from './components/Header/HeaderLookupEducation.jsx';
import CertificateInfo from './pages/Certificate';
import EducationInfo from './pages/Education';
import "assets/css/style.css"


var styles = {
  background: {
    width: "100%",
    margin: "auto",

  },
  loadingBackground: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#fff",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"

  },
  textBoldTitle: {
    color: "#444345",
    fontWeight: "500",
    textAlign: "center"
  },
  textTitle: {
    color: "#444345",
    textAlign: "center",
    fontSize: "18px",
  },
  buttonActive: {
    border: "1px solid #4a443a !important",
    borderRadius: "2px !important",
    fontSize: "12px",
    color: "#4a443a !important",
    backgroundColor: "#fff",
    fontWeight: "400 !important",
    padding: "8px 20px !important",
    textTransform: "none !important",
  },
};

class InfoPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      load: true,
      timeout: false,
      loadSuccess: false,
      type: false,
      lookup_data: {
        component: null,
      },
      diary_data: {
        component: null,
      }
    }
    this.lookup_gln = this.props.match.params.id;
  }

  async componentDidMount() {
      await  this.props.cleanStore();
      await this.props.getOverview(this.lookup_gln)
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      this.setState({
        loading: true
      })
      window.location.reload();
    }
    if(nextProps.overview !== this.props.overview){
     
      if(nextProps.overview.intro === null){
        this.setState({
          load: false,
          loadSuccess: false
        })
        setTimeout(
          function () {
            this.setState({ timeout: true });
          }
            .bind(this),
          10000
        );
      }else{
        if(nextProps.overview.intro !== "Loading"){
          // if(nextProps.match.path === "/lookup/:id"){
          //   this.setState({
          //     loadSuccess: true,
          //     load: false,
          //     lookup_data:{
          //       component: <ProductInfo />
          //     },
          //     header: <HeaderLookup />
          //   })
          // }
           if(nextProps.match.path === "/profile/:id"){
            this.setState({
              loadSuccess: true,
              load: false,
              lookup_data:{
                component: <EducationInfo />
              },
              header: <HeaderLookupEducation />
            })
          }
          else if(nextProps.match.path === "/certificate/:id" && (nextProps.match.params.id === "0xf30655e2a0009acfbe3ebe22db8621289466202c162f8c11b8ed755ce68b1a7c" || nextProps.match.params.id === "0x8eac6e36caedebd8fbda9683ea5d221770354162f101adf851ea1e467f99cb8a" )){
            this.setState({
              loadSuccess: true,
              load: false,
              lookup_data:{
                component: <CertificateInfo />
              },
              header: <HeaderLookupEducation />
            })
          }
          else{
            this.setState({
              loadSuccess: false,
              load: false,
              timeout: true
            })
          }
        }
      }
    }
  }

  render() {
    const { classes } = this.props;
    const emptybox = "https://res.cloudinary.com/agridential/image/upload/q_10/v1570696143/Logo/emptybox_skuvnl.png";
    const error = (
      <div>
        <GridItem lg={12} md={12} sm={12} xs={12} className={classes.background}>
          <div style={{ maxWidth: "470px", margin: "auto" }}>
            <img  alt="empty" style={{ display: "inherit", marginTop: "30px", maxWidth: "100%", height: "auto" }} src={emptybox} />
          </div>
          <h3 className={classes.textBoldTitle}>Sản phẩm chưa được kích hoạt</h3>
          <h5 className={classes.textTitle}>Vui lòng liên hệ nhà bán lẻ hoặc địa chỉ email: <a href="mailto:contact@vietnamblockchain.asia">contact@vietnamblockchain.asia</a> để có thêm thông tin về giải pháp.</h5>
          <div style={{ textAlign: "center", marginTop: "20px", marginBottom: "20px" }}>
            <Button className={classes.buttonActive} href="/" color="white">
              Quay Lại
            </Button>
            {(this.state.timeout) ?
              <Redirect to="/"></Redirect> : <div></div>}
          </div>
        </GridItem>
      </div>
    );


    return (
      <div style={styles.content}>
        {(this.state.load) ?
          <GridItem lg={12} md={12} sm={12} xs={12} className={classes.loadingBackground}>
            <img alt="loading" src={IMG_LOAD} style={{ height: '100px', marginTop: '40px' }} />
          </GridItem>
          : <div></div>}
        {(this.state.loadSuccess) ?
          <div >
            {(this.state.type === "CERTIFICATE") ? <div></div> : this.state.header}
            {this.state.lookup_data.component}  
          </div>
          : (!this.state.load) ? error : <div></div>}
      </div>
    );
  }
}


InfoPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    overview: state.overview
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
    cleanStore: () => dispatch({type: "CLEAN_STORE"})
  }
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InfoPage));
