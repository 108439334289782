import { Typography } from '@material-ui/core';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
        //this.data = this.props.overview;
    }
    componentDidMount(){
        if(this.props.overview.length > 0){
            this.setState({
                data: this.props.overview
            })
        }
    }
    componentWillReceiveProps(preProps){
        if(preProps.overview !== this.props.overview){
            if(preProps.overview){
                this.setState({
                    data: preProps.overview
                })
            }
        }
    }

    render() {
        const renderData = this.state.data.map((item, index) => {
            return(
            <div key={index}>
                {item.value.length > 0 ? <div>
                    <p className="detailtitle">{item.key}</p>
                {item.value.map((x, i) => {
                    return (
                        <Typography className="textdes" style={{ marginTop: '10px' }}>
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={3} lg={3}>
                                    <strong>{x.key}</strong>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={9} lg={9}>
                                    <BrowserView>
                                        {' '}
                                        {x.value?(
                                            x.value.split('\n').map((item, i) => {
                                                return <div key={i}>{item}</div>;
                                            })
                                        ):null}
                                    </BrowserView>
                                    <MobileView>
                                    <div style={{ marginTop: 8 }}>{
                                            x.value?(
                                                x.value.split('\n').map((item, i) => {
                                                    return <div key={i}>{item}</div>;
                                                })
                                            ):null
                                        }</div>
                                    </MobileView>
                                </GridItem>
                            </GridContainer>
                        </Typography>
                    );
                })}
                <hr className="LineBreakDes" />
                </div>:null}
            </div>
            )
        })
        return (
            <div>
                {renderData}
            </div>
        )
    }
}


export default Overview;