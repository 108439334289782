const initialState = { 
    stories: [],
    video: "",
    totalPage: 0,
    pages: [],
    tracking: []
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_DIARY':
            return {
                ...state,
            };
        case 'GET_DIARY_SUCCESS':
           // console.log(action)
            return {
                ...state,
                stories: action.stories,
                video: action.video,
                totalPage: action.totalPage,
                pages: action.pages,
                tracking: action.tracking
            };
        case 'GET_DIARY_FALURE':
            return state
        default:
            return state;
    }
}
export default reducer;