import { withStyles } from '@material-ui/core/styles';
import Card from 'components/Card/Card.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import React from 'react';
import { connect } from 'react-redux';
import { Events, scroller } from 'react-scroll';
import Footer from '../../components/Footer/footer.jsx';
import styles from './infoStyles';
import Journey from './journey';
import Manufacturer from './manufacturer';
import Overview from './overview';
import ProfileIcon from "mdi-react/FaceProfileIcon"
import UpIcon from "mdi-react/AppleKeyboardControlIcon"
import CertIcon from "mdi-react/BadgeAccountHorizontalIcon"
class LookupRice extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			trial: false,
			banner: "",
			activeTab: 1,
			scrollTop: "none"
		};
		this.handleActive = this.handleActive.bind(this);
		this.scrollToWithContainer = this.scrollToWithContainer.bind(this);
		this.handleActiveScroll = this.handleActiveScroll.bind(this);
		this.handleOnlyActiveTab = this.handleOnlyActiveTab.bind(this);
	}
	scrollToWithContainer(name) {

		new Promise((resolve, reject) => {

			Events.scrollEvent.register('end', () => {
				resolve();
				Events.scrollEvent.remove('end');
			});

			scroller.scrollTo(name, {
				duration: 800,
				delay: 0,
				smooth: 'easeInOutQuart'
			});

		});
	}
	handleActiveScroll() {
		var y_position = window.pageYOffset;
		var diary_content = document.getElementById('diary').offsetTop;
		var manufacturer_content = document.getElementById('manufacturer').offsetTop;
		if (y_position > manufacturer_content && y_position < diary_content) {
			this.handleOnlyActiveTab(1)
			this.setState({
				scrollTop: ""
			})
		}
		if (y_position > diary_content) {
			
			this.handleOnlyActiveTab(2)
		}
		if(y_position < manufacturer_content ){
			this.setState({
				scrollTop: "none"
			})
		}
	}
	handleOnlyActiveTab(id) {
		this.setState({
			activeTab: id
		})
	}
	async componentDidMount() {
		if (this.props.overview) {
			if (this.props.overview.banner) {
				this.setState({
					banner: this.props.overview.banner
				})
			}
		}
		Events.scrollEvent.register('begin', function () {
			//console.log("begin", arguments);
		});

		Events.scrollEvent.register('end', function () {
			//console.log("end", arguments);
		});
		window.addEventListener('scroll', this.handleActiveScroll);
	}
	componentWillReceiveProps(preProps) {
		if (preProps.overview !== this.props.overview) {
			this.setState({
				banner: preProps.overview.banner
			})
		}
	}
	handleActive = (id, name) => {
		this.setState({
			activeTab: id
		})
		this.scrollToWithContainer(name)
	}
	render() {
		const { classes } = this.props;

		return (
			<div>
				<div className={classes.toTop} style={{display: this.state.scrollTop}}onClick={() => this.scrollToWithContainer('topContent')}>
					<UpIcon size={30} style={{ fill: "#fff" }} />
				</div>
				<div className="contentparent" id="topContent">
					{this.state.banner ? <GridItem xs={12} className="contentnopad">
						<img alt="banner" src={this.state.banner} style={{ width: '100%', marginTop: "2%" }}></img>
					</GridItem> : null}
					<GridItem xs={12} className="contentnopad" style={{ marginTop: '-20px' }}>
						<Overview setClick={click => this.clickOverview = click} />
					</GridItem>
					<GridItem xs={12} id="manufacturer" className="contentnopad" style={{ marginTop: '-50px' }}>
						<Card>
							<Manufacturer />
						</Card>

					</GridItem>
					<GridItem xs={12} id="diary" className="contentnopad">
						<Journey />
					</GridItem>
					<div className="contentnopadbar">
						<Card>
							<div >
								<GridContainer>
									<GridItem xs={6} sm={6} md={6} lg={6} className={classes.centerIcon} onClick={() => this.handleActive(1, 'manufacturer')}>
										<div className={this.state.activeTab === 1 ? classes.cardIconActive : classes.cardIcon}>
											<ProfileIcon size={30} style={this.state.activeTab === 1 ? { fill: "#1e90ff" } : { fill: "rgb(134, 142, 150)" }} />
											<p className={this.state.activeTab === 1 ? classes.textIconActive : classes.textIcon}>Cá nhân</p>
										</div>
									</GridItem>
									<GridItem xs={6} sm={6} md={6} lg={6} className={classes.centerIcon} onClick={() => this.handleActive(2, 'diary')}>
										<div className={this.state.activeTab === 2 ? classes.cardIconActive : classes.cardIcon}>
											<CertIcon size={30} style={this.state.activeTab === 2 ? { fill: "#1e90ff" } : { fill: "rgb(134, 142, 150)" }} />
											<p className={this.state.activeTab === 2 ? classes.textIconActive : classes.textIcon}>Chứng chỉ</p>
										</div>
									</GridItem>
									{/* <GridItem xs={4} sm={4} md={4} lg={4} className={classes.centerIcon} onClick={()=>this.handleActive(3)}>
									<div className={this.state.activeTab=== 3 ? classes.cardIconActive :classes.cardIcon}>
									<LocationIcon size={30}  style={this.state.activeTab=== 3 ? {fill: "#1e90ff"} :{fill: "rgb(134, 142, 150)"}}/>
									<p className={this.state.activeTab=== 3 ? classes.textIconActive : classes.textIcon}>Check in</p>
									</div>
									</GridItem> */}
								</GridContainer>
							</div>
						</Card>
					</div>
				</div>
				<div style={{ marginTop: '-18px' }}>
					<Footer />
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		overview: state.overview
	}
}
const mapDispatchToProps = (dispatch) => {
	return {
		getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
		getDiary: (id) => dispatch({ type: "GET_DIARY", id: id }),
		cleanStore: ()  => dispatch({type: "CLEAN_STORE"})
	}
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(LookupRice));
