const initialState = { id: ""};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SAVE_ID':
            return {
                ...state,
            };
        case 'SAVE_ID_SUCCESS':
           // console.log(action)
            return {
                ...state,
               id: action.id
            };
        case 'SAVE_ID_FALURE':
            return state
        default:
            return state;
    }
}
export default reducer;