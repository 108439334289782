import { withStyles } from '@material-ui/core/styles';
import jsQR from "jsqr";
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "./scan.css";
import styles from "./styles.js";
import { connect } from 'react-redux';

class Scan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      delay: 500,
      result: 'Đưa camera hướng về phía mã QR của sản phẩm',
     
    }

    this.handleScan = this.handleScan.bind(this)
    //this.tick = this.tick.bind(this)
  }

  async componentDidMount() {
    var video = document.createElement("video");
    video.setAttribute('width', "200px")
    video.setAttribute('height', "200px")
    var canvasElement = document.getElementById("canvas");
    var canvas = canvasElement.getContext("2d");
    var loadingMessage = document.getElementById("loadingMessage");
    // var outputContainer = document.getElementById("output");
    // var outputMessage = document.getElementById("outputMessage");
    // var outputData = document.getElementById("outputData");

    const drawLine = (begin, end, color) => {
      canvas.beginPath();
      canvas.moveTo(begin.x, begin.y);
      canvas.lineTo(end.x, end.y);
      canvas.lineWidth = 4;
      canvas.strokeStyle = color;
      canvas.stroke();
    }
    const tick = () => {
      loadingMessage.innerText = "⌛ Đang tải..."
      if (video.readyState === video.HAVE_ENOUGH_DATA) {
        loadingMessage.hidden = true;
        canvasElement.hidden = false;
        //outputContainer.hidden = false;
        canvasElement.height = video.videoHeight;
        canvasElement.width = video.videoWidth;
        canvas.drawImage(video, 0, 0, canvasElement.width, canvasElement.height);
        var imageData = canvas.getImageData(0, 0, canvasElement.width, canvasElement.height);
        var code = jsQR(imageData.data, imageData.width, imageData.height, {
          inversionAttempts: "dontInvert",
        });
        if (code) {
          drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58");
          drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58");
          drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58");
          drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58");
          // outputMessage.hidden = true;
          // outputData.parentElement.hidden = false;
          // outputData.innerText = code.data;
          handleData(code.data);
        } else {
          // outputMessage.hidden = false;
          // outputData.parentElement.hidden = true;
        }
      }
      requestAnimationFrame(tick);
    }
    const handleData = async (result) => {
      if (result) {
        var i = 0;
        var index = 0;
        for (i; i < result.length; i++) {
          if (result[i] === '/') {
            index = i;
          }
        }
        if (index > 0) {
          var finalresult = result.slice(index + 1, result.length);
          await this.props.saveId(finalresult);
          //this.props.history.push("/checkIn")
          //let path = "https://portal.agridential.vn/lookup/" + finalresult;
          let path = "https://checkin.vietnamblockchain.asia/checkIn"
          //let path = "http://localhost:3000/checkIn"
          window.location.replace(path);
        } else {
          let path = "https://portal.agridential.vn/lookup/" + result;
          window.location.replace(path);
        }
  
      }
    }
    const handleUserMediaSuccess = (stream) => {
  
      video.srcObject = stream;
      video.play()
      requestAnimationFrame(tick);
    }
    const handleUserMediaError = (error) => {
      console.log("error", error)
    }

    var DEVICES = [];
    var final = null;

    navigator.mediaDevices.enumerateDevices()
    .then(function(devices){
      var arrayLength = devices.length;
        for (var i = 0; i < arrayLength; i++)
        {
            var tempDevice = devices[i];
            if (tempDevice.kind === "videoinput")
            {
                DEVICES.push(tempDevice);
                if(tempDevice.facingMode === "environment" ||tempDevice.label.indexOf("facing back")>=0 )
                    {final = tempDevice;}
            }
        }

        var totalCameras = DEVICES.length;
        if(final == null)
        {
            final = DEVICES[totalCameras-1];
        };
        var constraints = {
        audio: false, 
        video: {
            deviceId: {exact: final.deviceId}
            }
        };
        if (typeof navigator.mediaDevices.getUserMedia === 'undefined') {
          navigator.getUserMedia(constraints, handleUserMediaSuccess, handleUserMediaError);
        } else {
          navigator.mediaDevices.getUserMedia(constraints)
            .then(function (stream) {
              handleUserMediaSuccess(stream)
            })
            .catch(err => {
              handleUserMediaError(err)
            })
        }
    })
    .catch(err => {
      console.log(err)
    })
  }

  handleScan(result) {
    if (result) {
      var i = 0;
      var index = 0;
      for (i; i < result.length; i++) {
        if (result[i] === '/') {
          index = i;
        }
      }
      if (index > 0) {
        var finalresult = result.slice(index + 1, result.length);
        this.setState({ finalresult });
        // let path = 'search/'+result;
        let path = "https://portal.agridential.vn/lookup/" + result;
        //this.props.history.push(path);
        window.location.href = path;
      } else {
        this.setState({ result });
        // let path = 'search/'+result;
        let path = "https://portal.agridential.vn/lookup/" + result;
        //this.props.history.push(path);
        window.location.href = path;
      }

    }
  }

  handleError(err) {
    console.error(err)
  }
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <img alt="logo" src={"https://res.cloudinary.com/agridential/image/upload/v1583393051/images/logo_vbc_bn8cog.png"} className={classes.logo} />
        </div>
        <div style={{width: "100%", textAlign:"center", display: "flex", flexFlow: "column", alignItems:"center"}}>
        <p className={classes.title1}>Gateway for connecting</p>
        <p className={classes.title2}>to VBC Blockchain ecosystem</p>
        <div id="loadingMessage">Không thể truy cập camera!</div>
        <div style={{width: "300px", height: "300px", marginTop: "20px"}}>
        <canvas id="canvas" hidden></canvas>
        </div>
        <div className={classes.link}>
        <a href="https://vietnamblockchain.asia/" className={classes.address}>www.vietnamblockchain.asia</a>
        </div>
        </div>
      
       
        {/* <div id="output" hidden>
          <div id="outputMessage">Không tìm thấy mã QR!</div>
          <div hidden><b>Dữ liệu:</b> <span id="outputData"></span></div>
        </div> */}

      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    checkIn: state.checkIn
  }
}
const mapDispatchToProps = (dispatch) => {
  return {
    getOverview: (id) => dispatch({ type: "GET_OVERVIEW", id: id }),
    saveId: (id) => dispatch({ type: "SAVE_ID", id: id }),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(Scan)));